import React from 'react'
import './style.css'
import {PropsType} from "./types";

export const InformationBlock: React.FC<PropsType> = (props) => {

    const {label, children} = props

    return (
        <div className='InformationBlock'>
            <div className='InformationBlock__label'>{label}</div>
            <div className='InformationBlock__value'>{children}</div>
        </div>
    )
}