import {useEffect, useState} from "react";

type UseCommissionPropsType = {
    amount: number,
    minimal: number,
    commission: number,
}

export function useCommission({amount, minimal, commission}: UseCommissionPropsType) {

    const [calculations, setCalculations] = useState<number>(0.0);

    function calc(): number {

        const result = (amount / 100 * commission) * 100;

        if (result > minimal) {
            return result
        } else {
            return minimal
        }
    }

    useEffect(() => {
        if (amount) {
            setCalculations(calc())
        }
    }, [amount])

    return {
        amount: (amount ? (amount).toFixed(2) : "—"),
        calculations: (calculations ? calculations.toFixed(2) : "—"),
        amountSeller: ((amount - calculations > 0) ? (amount - calculations).toFixed(2) : "—"),
    }
}
