export const SET_DEAL_ID = '@dealReducer/SET_DEAL_ID'
export const SET_SELLER = '@dealReducer/SET_SELLER'
export const SET_BUYER = '@dealReducer/SET_BUYER'
export const SET_SUM = '@dealReducer/SET_SUM'
export const SET_SELLER_EMAIL = '@dealReducer/SET_SELLER_EMAIL'
export const SET_BUYER_EMAIL = '@dealReducer/SET_BUYER_EMAIL'
export const SET_DEAL = '@dealReducer/SET_DEAL'
export const SET_ORDER_ID = '@dealReducer/SET_ORDER_ID'
export const SET_REQUEST_ID = '@dealReducer/SET_REQUEST_ID'
export const SET_PA_REQ = '@dealReducer/SET_PA_REQ'
export const SET_COMMISSION = '@dealReducer/SET_COMMISSION'
export const SET_ACS_URL = '@dealReducer/SET_ACS_URL'
export const SET_REDIRECT = '@dealReducer/SET_REDIRECT'


export type ClientType = {
    id: number | null
    email: string | null
    lastName: string | null
    firstName: string | null
    middleName: string | null
    cardNumber?: string | null
}

export type DealType = {
    id: number | null
    name: string | null
    amount: number | null,
    description: string | null
}

export type RedirectType = {
    url: string,
    method: string,
    params: object[]
}

export type InitialStateType = {
    dealId: number | null
    deal: DealType | null
    sum: number | null
    seller: ClientType | null
    buyer: ClientType | null
    sellerEmail: string | null
    buyerEmail: string | null
    orderId: string | null
    requestId: string | null
    paReq: string | null
    acsUrl: string | null
    commission: number | null,
    redirect: RedirectType | null
}

export type setDealIdType = {
    type: typeof SET_DEAL_ID,
    payload: number | null
}

export type setSumType = {
    type: typeof SET_SUM
    payload: number | null
};

export type setSellerType = {
    type: typeof SET_SELLER
    payload: ClientType
};

export type setBuyerType = {
    type: typeof SET_BUYER
    payload: ClientType
};

export type setSellerEmailType = {
    type: typeof SET_SELLER_EMAIL
    payload: string | null
};

export type setBuyerEmailType = {
    type: typeof SET_BUYER_EMAIL
    payload: string | null
};

export type setDealType = {
    type: typeof SET_DEAL
    payload: DealType | null
};

export type setOrderIdType = {
    type: typeof SET_ORDER_ID
    payload: string | null
};

export type setRequestIdType = {
    type: typeof SET_REQUEST_ID
    payload: string | null
};

export type setPaReqType = {
    type: typeof SET_PA_REQ
    payload: string | null
};

export type setCommissionType = {
    type: typeof SET_COMMISSION
    payload: number | null
};

export type setAcsUrlType = {
    type: typeof SET_ACS_URL
    payload: string | null
};

export type setRedirectType = {
    type: typeof SET_REDIRECT
    payload: RedirectType | null
};


export type DealReducerActionType =
    | setDealIdType
    | setSumType
    | setSellerType
    | setBuyerType
    | setSellerEmailType
    | setBuyerEmailType
    | setDealType
    | setOrderIdType
    | setRequestIdType
    | setPaReqType
    | setCommissionType
    | setAcsUrlType
    | setRedirectType








