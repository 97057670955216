import React, {FC, useContext} from "react";
import './style.css'
import {startSectionImages} from "../../../../assets/images/landings/main";
import {MediaQueryContext} from "../../../../contexts/MediaQueryContext";
import {ui} from "../../../ui";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {routes} from "../../../../routes";

const {Button} = ui
const {
    FeatureIcon1,
    FeatureIcon2,
    FeatureIcon3,
    startBg,
} = startSectionImages

export const StartSection: FC = () => {

    /* hooks */
    const history = useHistory()
    const media = useContext(MediaQueryContext)
    const {t} = useTranslation()

    /* methods */
    const handleToDeal = () => history.push(routes.operation.creating)

    return (
        <section className='MainLanding__StartSection Grid'>
            <div className='left-content'>
                <div className='MainLanding__StartSection__features'>
                    <div className='MainLanding__StartSection__feature__item'>
                        <div className='MainLanding__StartSection__feature-line'/>
                        <img src={FeatureIcon1}/>
                        <p>{t('Main.StartSection.feature1', 'Быстро и легко')}</p>
                        <div className='MainLanding__StartSection__feature-line'/>
                    </div>
                    <div className='MainLanding__StartSection__feature__item'>
                        <div className='MainLanding__StartSection__feature-line'/>
                        <img src={FeatureIcon2}/>
                        <p>{t('Main.StartSection.feature2', 'Круглосуточная поддержка')}</p>
                        <div className='MainLanding__StartSection__feature-line'/>
                    </div>
                    <div className='MainLanding__StartSection__feature__item'>
                        <div className='MainLanding__StartSection__feature-line'/>
                        <img src={FeatureIcon3}/>
                        <p>{t('Main.StartSection.feature3', 'API для партнеров')}</p>
                        <div className='MainLanding__StartSection__feature-line'/>
                    </div>
                </div>
                <div className='SectionTitle'>
                    {t('Main.StartSection.title', 'Сервис для проведения безопасных сделок в Интернете')}
                </div>
                <div className='MainLanding__StartSection__Buttons'>
                    <Button onClick={handleToDeal}>
                        {t('Main.StartSection.button1', 'Безопасно купить')}
                    </Button>
                    <Button onClick={handleToDeal}>
                        {t('Main.StartSection.button2', 'Безопасно продать')}
                    </Button>
                </div>
            </div>
            <img src={startBg[media]} className='MainLanding__StartSection__bg' alt='Картинка смартфона'/>
        </section>
    )
}
