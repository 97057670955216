import React, {FC} from 'react'
import './style.css'
import Background from './images/bg.svg'

type StartSectionWaveType = {
    title: string,
}

export const StartSectionWave:FC<StartSectionWaveType> = (props) => {
    const {
        title,
        children
    } = props

    return (
        <section className='StartSectionWave Grid'>
            <h2 className='PageTitle'>
                {title}
            </h2>
            <h4 className='StartSectionWave__subtitle'>
                {children}
            </h4>
            <img src={Background} alt='Волны'/>
        </section>
    )
}