import React, {FC} from "react";
import './style.css'
import classNames from 'classnames'
import {PropsType} from "./types";

export const AlertContainer: FC<PropsType> = (props) => {

    /* props */
    const {children, className, ...all} = props

    /* classes */
    const classes = classNames(className, {
        'AlertContainer': true,
    });

    return (
        <div className={classes} {...all}>
            {children}
        </div>
    )
}