/* components */
import {InformationBlock} from "./InformationBlock";
import {AlertContainer} from "./AlertContainer";
import {AddingButton} from "./AddingButton";
import {Input} from "./Fields/Input";
import {BankCard} from "./BankCard";
import {Dropdown} from "./Dropdown";
import {Textarea} from './Textarea';
import {Button} from "./Button";
import {Field} from "./Fields";
import {Rub} from "./Rub";

/* hooks */
import {useValidation} from "./hooks/useValidation";
import {useCheckbox} from "./hooks/useCheckbox";
import {useField} from "./hooks/useField";
import {useBankCardField} from "./hooks/useBankCardField";
import {useMediaQuery} from "./hooks/useMediaQuery";
import {useDragHook} from "./hooks/useDrag";
import {useSelect} from "./hooks/useSelect";
import {useScrollToTop} from "./hooks/useScrollToTop";
import {useCallbackForm} from "./hooks/useCallbackForm";
import {useScrollToError} from "./hooks/useScrollToError";


export const ui = {
    InformationBlock,
    AlertContainer,
    AddingButton,
    Dropdown,
    Textarea,
    BankCard,
    Button,
    Field,
    Input,
    Rub
}

export const hooks = {
    useValidation,
    useCheckbox,
    useField,
    useDragHook,
    useBankCardField,
    useScrollToTop,
    useMediaQuery,
    useSelect,
    useCallbackForm,
    useScrollToError
}