import React from "react";
import {ui} from "../../ui";
import {paySystemsImages} from "../CardConnect/images";
import {Link, useLocation} from "react-router-dom";
import {Support} from "../../../supports";
import {LoadingAfter3ds} from "./Loading";
import {useTranslation} from "react-i18next";
import {routes} from "../../../routes";

const {AlertContainer, Button} = ui;

export const GetStatus = () => {

    /* hooks */
    const location = useLocation()
    const dealId: number = new Support().getNumber(location.pathname)
    const {t} = useTranslation()

    return (
        <section className='CardConnect Grid DealPage'>
            <AlertContainer className='DealFieldsContainer--small'>
                <h3>{t('Deal.GetStatus.title', 'Оплата картой')}</h3>
                <LoadingAfter3ds dealId={dealId}/>
                <div className='CardConnect__pay-systems'>
                    <img src={paySystemsImages[0]} alt="visa"/>
                    <img src={paySystemsImages[1]} alt="mastercard"/>
                    <img src={paySystemsImages[2]} alt="mir"/>
                    <img src={paySystemsImages[3]} alt="pci"/>
                </div>
            </AlertContainer>
            <div className='CardConnect__confirm'>
                <Link to={routes.operation.creating}>
                    <Button variant='empty-blue'>
                        {t('Deal.GetStatus.buttonBack', 'Назад')}
                    </Button>
                </Link>
                <Link to="/?support=true">
                    <Button variant='empty-black'>
                        {t('Deal.GetStatus.techSupport', 'Техническая поддержка')}
                    </Button>
                </Link>
            </div>
        </section>
    )
}


