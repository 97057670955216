import informationListItem from './informationSection/informationListItem.svg'
import shieldImage1920 from './informationSection/Shield/Shield1920.png'
import shieldImage1024 from './informationSection/Shield/Shield1024.png'
import FeatureIcon1 from './startSection/FeatureIcon_1.svg'
import FeatureIcon2 from './startSection/FeatureIcon_2.svg'
import FeatureIcon3 from './startSection/FeatureIcon_3.svg'
import FeatureLine from './startSection/FeatureLine.svg'
import startBg1920 from './startSection/Bg/Bg1920.png'
import startBg1440 from './startSection/Bg/Bg1440.png'
import startBg1024 from './startSection/Bg/Bg1024.png'
import startBg720 from './startSection/Bg/Bg720.png'
import dealBg from './dealSection/Bg.svg'
import rocket from './serviceBenefits/rocket.svg'
import serviceAudienceImage1 from './serviceAudience/firstCard.png'
import serviceAudienceImage2 from './serviceAudience/secondCard.png'
import serviceAudienceImage3 from './serviceAudience/thirdCard.png'
import serviceAudienceImage4 from './serviceAudience/fourthCard.png'
import partnerIcon1 from './finalSection/icon1.svg'
import partnerIcon2 from './finalSection/icon2.svg'
import partnerIcon3 from './finalSection/icon3.png'
import partnerIcon4 from './finalSection/icon4.svg'
import partnerIcon5 from './finalSection/icon5.svg'

export const startSectionImages = {
    FeatureIcon1,
    FeatureIcon2,
    FeatureIcon3,
    FeatureLine,
    startBg: {
        mobile: startBg720,
        tablet_2: startBg720,
        tablet_1: startBg1024,
        laptop: startBg1440,
        desktop: startBg1920,
    }
}

export const dealSectionImages = {
    dealBg
}

export const informationImages = {
    shieldImage: {
        mobile: shieldImage1024,
        tablet_2: shieldImage1024,
        tablet_1: shieldImage1024,
        laptop: shieldImage1920,
        desktop: shieldImage1920,
    },
    informationListItem
}

export const serviceBenefitsImages = {
    rocket
}

export const serviceAudienceImages = {
    serviceAudienceImage1,
    serviceAudienceImage2,
    serviceAudienceImage3,
    serviceAudienceImage4
}

export const finalSectionImages = {
    partnerIcon1,
    partnerIcon2,
    partnerIcon3,
    partnerIcon4,
    partnerIcon5
}