import {useDispatch} from "react-redux";
import {actions} from "../../../store/DealReducer";

export function useClearDeal(): () => void {

    const dispatch = useDispatch()

    function handler() {
        const client = {
            id: null,
            email: null,
            lastName: null,
            firstName: null,
            middleName: null,
            cardNumber: null
        }

        dispatch(actions.setBuyer(client))
        dispatch(actions.setSeller(client))
        dispatch(actions.setDealId(null))
        dispatch(actions.setSum(null))
        dispatch(actions.setDeal({
            id: null,
            name: null,
            amount: null,
            description: null,
        }))

    }

    return handler
}
