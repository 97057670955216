export class Support {

    clear(Str: string, pattern?: string, replace?: string): string {
        const p = pattern || /[a-z,A-Z\D]/gi;
        const r = replace || ''
        return Str.replace(p, r)
    }

    random(prefix: string): string {
        return prefix + (~~(Math.random() * 1e8)).toString(16)
    }

    getNumber(Str: string): number {
        return parseInt(Str.replace(/[^\d]/g, ''))
    }

    words(Str: string): string[] {
        return Str.replace(/\s/gi, '%#%').split('%#%')
    }

    queryParams(search: string): object {

        let params: any = {}

        search.split(/[?|&]/gi).map((item: string) => {
            const [key, value] = item.split("=");
            if (key && value) params[key] = value;
        });
        return params
    }
}


