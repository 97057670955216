import React, {Fragment} from 'react'
import {AlertContainer} from "../../../ui/AlertContainer";
import {useHistory} from 'react-router-dom';
import {hooks, ui} from "../../../ui";
import {useTranslation} from "react-i18next";

const {Button} = ui

export const SuccessStatus = () => {

    /* hooks */
    const history = useHistory();
    const {t} = useTranslation()

    /* methods */
    const handleBackToDeal = () => history.push('/')

    return (
        <Fragment>
            <AlertContainer>
                {t('Deal.Arbitration.fields.success', 'Спасибо, Ваше сообщение будет рассмотрено в ближайшее время и вы получите ответ на почту.')}
            </AlertContainer>
            <Button onClick={handleBackToDeal}>
                {t('Deal.Arbitration.fields.buttons.back', 'На главную')}
            </Button>
        </Fragment>
    )
}