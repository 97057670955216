import React, {FC, useEffect} from 'react'
import './style.css'
import {useDispatch, useSelector} from "react-redux";
import {selectors, Thunk} from "../../../../store/DealReducer";
import {methods} from "../../../../api/methods";
import {Trans} from "react-i18next";

export const LoadingAfter3ds: FC<{ dealId: number }> = ({dealId}) => {

    /* hooks */
    const dispatch = useDispatch();
    const [getters, setters] = methods.useSetters({initialLoading: true});


    /* DAL */
    const {getDealStatusAfter3ds} = Thunk(setters);

    useEffect(() => {
        let timer: any = null;
        if (getters.loading && dealId) {
            timer = setInterval(() => {
                dispatch(getDealStatusAfter3ds({dealId}))
            }, 3000)
        }
        return () => clearInterval(timer)
    }, [getters.loading])


    return (
        <div className='Status'>
            <div className="lds-default">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
            <div>
                <Trans i18nKey='Deal.GetStatus.loading'>
                    Пожалуйста,<br/> подождите
                </Trans>
            </div>
        </div>
    )
}