import {methods} from './methods';
import {instance} from './axios';
import {
    ICallback,
    IClient,
    IClientCreate,
    IClientUpdate,
    IDeal,
    IDealApprove,
    IDealGet,
    IDealStart,
    IFeedback
} from './types';
import {Support} from "../supports";

const support = new Support()


const headersForPost = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
}

export const Client = {
    get: async (props: IClient) => {
        return await methods.Responses(
            instance.get(`/client?email=${props.email}`)
        )
    },
    create: async (props: IClientCreate) => {

        const phone = support.clear(`${props.phone}`);
        const data = {
            email: props.email,
            last_name: props.lastName,
            first_name: props.firstName,
            middle_name: props.middleName,
        }

        return await methods.Responses(
            instance.post('/client', (
                Boolean(props.phone)
                    ? {phone, ...data}
                    : data
            ))
        )
    },
    update: async (props: IClientUpdate) => {
        return await methods.Responses(
            instance.put(`/client/${props.clientId}`, {
                email: props.email,
                phone: props.phone,
                last_name: props.lastName,
                first_name: props.firstName,
                middle_name: props.middleName,
                card_number: support.clear(String(props.cardNumber)),
            })
        )
    },
}


export const Deal = {
    create: async (props: IDeal) => {

        const cardNumber = support.clear(`${props.card_number}`);

        return await methods.Responses(
            instance.post('/operation', {
                name: props.name,
                amount: props.amount,
                buyer_id: props.buyerId,
                seller_id: props.sellerId,
                description: props.description,
                sender_city: props.sender_city,
                sender_address: props.sender_address,
                sender_country: props.sender_country,
                card_number: cardNumber
            })
        )
    },
    get: async (props: IDealGet) => {
        return await methods.Responses(
            instance.get(`/operation/${props.dealId}`)
        )
    },
    start: async (props: IDealStart) => {
        return await methods.Responses(
            instance.put(`/operation/${props.dealId}/start`, {
                exp_year: props.expYear,
                exp_month: props.expMonth,
                card_number: props.cardNumber,
                card_holder: props.cardHolder,
                cvv: props.cvv,
            })
        )
    },
    approve: async (props: IDealApprove) => {
        return await methods.Responses(
            instance.put(`/operation/${props.dealId}/approve`, {
                pa_res: props.paRes
            })
        )
    },
}

export const Callback = {
    post: async (props: ICallback) => {
        return await methods.Responses(
            instance.post('/callback', {
                OrderInfo: {
                    ExtId: props.OrderInfo.ExtId,
                    OrderId: props.OrderInfo.OrderId,
                    State: props.OrderInfo.State,
                    StateDescription: props.OrderInfo.StateDescription,
                    Type: props.OrderInfo.Type,
                    Amount: props.OrderInfo.Amount,
                    ExecDateTime: props.OrderInfo.ExecDateTime
                }
            })
        )
    },
}


export const Feedback = {
    post: async (props: IFeedback) => {
        return await methods.Responses(
            instance.post('/feedback', {
                email: props.email,
                message: props.message,
                full_name: props.fullName
            })
        )
    },
}
