import React, {FC, ReactNode, useState} from 'react'
import InputMask from 'react-input-mask'
import cn from 'classnames'

type CustomTypes = {
    hint?: string
    label?: string
    error?: string
    support?: string | ReactNode
    loading?: boolean
    mask?: string | Array<(string | RegExp)>;
    maskChar?: string;
    alwaysShowMask?: boolean;
    formatChars?: Record<string, string>;
    permanents?: number[];
    variant?: 'border',
    onSupport?(): void
}

export type PropsType = CustomTypes & React.AllHTMLAttributes<HTMLInputElement>

export const Input: FC<PropsType> = (props) => {

    const {
        hint,
        error,
        label,
        value,
        style,
        support,
        loading,
        disabled,
        onSupport,
        className,
        mask = '',
        variant,
        defaultValue,
        maskChar = null,
        ...other
    } = props;

    /* state */
    const [title, setTitle] = useState<boolean>(false)

    function toggleTitle() {
        setTitle(prev => !prev)
    }

    return (
        <div style={style} className={cn(className, {
            'Input': true,
            'Input--filled': !!value || !!defaultValue,
            'Input--disabled': disabled,
            'Input--loading': loading,
            'Input--error': !!error,
            'Input--border': variant=='border'
        })}>
            <InputMask
                mask={mask}
                value={value}
                maskChar={maskChar}
                disabled={disabled}
                defaultValue={defaultValue}
                {...other}
            />
            <label>{label}</label>
            {loading && (
                <div className='Input__loading'>

                </div>
            )}
            {support && (
                <div
                    className='Input__support'
                    onClick={onSupport}>
                    {support}
                </div>
            )}
            {error && (
                <div
                    title={error}
                    className='Input__error'
                    onClick={toggleTitle}>
                    {error}
                </div>
            )}
            {hint && (
                <div
                    title={hint}
                    className='Input__hint'
                    onClick={toggleTitle}>
                    {hint}
                </div>
            )}
            {title && (
                <div
                    title={hint || error}
                    className={cn({
                        'Input__title': true,
                        'Input__title--error': !!error
                    })}
                    onClick={toggleTitle}>
                    {hint || error}
                </div>
            )}
        </div>
    )
}