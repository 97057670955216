import React, {useState, useEffect} from "react";


type ReturnedValue = {
    cardCvv: {
        value: string
        setValue: (value: string) => void
    },
    cardDate: {
        value: string
        setValue: (value: string) => void
    },
    cardNumber: {
        value: string
        setValue: (value: string) => void
    }
}

type PropsType = {
    initial?: {
        cardCvv: string,
        cardDate: string,
        cardNumber: string
    },
}

export const useBankCardField = (props: PropsType = {}): ReturnedValue => {

    const {initial} = props;

    /* state */
    const [value1, setValue1] = useState<string>(initial?.cardCvv || '');
    const [value2, setValue2] = useState<string>(initial?.cardDate || '');
    const [value3, setValue3] = useState<string>(initial?.cardNumber || '');

    /* side effect */
    useEffect(() => {
        setValue1(initial?.cardCvv || '')
        setValue2(initial?.cardDate || '')
        setValue3(initial?.cardNumber || '')
    }, [initial])

    return {
        cardCvv: {
            value: value1,
            setValue: setValue1
        },
        cardDate: {
            value: value2,
            setValue: setValue2
        },
        cardNumber: {
            value: value3,
            setValue: setValue3,
        }
    }
}
