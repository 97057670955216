import {useSpring} from "react-spring";
import {useDrag} from "react-use-gesture";
import {ReactEventHandlers} from "react-use-gesture/dist/types";

type useDragHookPropsType = {
    slideNext: ()=>void,
    slidePrev: ()=>void
}
type useDragHookType = {
    bind: (...args: any[]) => ReactEventHandlers
}

export const useDragHook = ({slideNext, slidePrev}:useDragHookPropsType):useDragHookType => {
    const [{}, set] = useSpring(() => ({
        x: 0,
        scale: 1
    }))
    const bind = useDrag(({ down, movement: [x] }) => {
        set({
            x: down ? x : 0,
            immediate: down
        })
        if (!down) {
            if (x<-50) {
                slideNext()
            }
            if (x>50) {
                slidePrev()
            }
        }
    })
    return {
        bind
    }
}
