import React from 'react'
import './style.css'
import {NoteElement} from "../../../uiSections/NoteElement";
import {commonImages} from "../../../../../assets/images/common";
import {useTranslation} from "react-i18next";

const {
    walletIcon,
    cardIcon
} = commonImages.statusIcons

export const AdditionalCommission = () => {
    const {t} = useTranslation()

    return (
        <section className='AdditionalCommission Grid'>
            <h2 className='SectionTitle'>
                {t('PaymentAndConditions.Additional.title', 'Дополнительные комиссии')}
            </h2>
            <div className='AdditionalCommission__content'>

                <div className='AdditionalCommission__table'>
                    <NoteElement img={cardIcon} title={t('PaymentAndConditions.Additional.getPayment.title', 'Получение оплаты от покупателя')}>
                        {t('PaymentAndConditions.Additional.getPayment.subtitle', 'Потребитель может заплатить за сделку посредством банковской карты или же через систему электронного платежа.')}
                    </NoteElement>
                    <ul>
                        <li>
                            {t('PaymentAndConditions.Additional.getPayment.methods', 'Комиссия при оплате через банковскую карту и другие способы')}
                        </li>
                    </ul>
                    <div className='AdditionalCommission__table__columns'>
                        <div className='AdditionalCommission__table__columns__line'/>

                        <b>
                            {t('PaymentAndConditions.Additional.getPayment.col1.title', 'Проведение платежа')}
                        </b>
                        <b>
                            {t('PaymentAndConditions.Additional.getPayment.col2.title', 'Срок зачисления*')}
                        </b>
                        <b>
                            {t('PaymentAndConditions.Additional.getPayment.col3.title', 'Комиссия за перевод**')}
                        </b>

                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col1.item1', 'Visa, MasterCard, МИР')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col2.item1', 'Online')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col3.item1', '3,3%')}
                        </div>

                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col1.item2', 'WebMoney')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col2.item2', 'Online')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col3.item2', '1,5%')}
                        </div>

                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col1.item3', 'Qiwi')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col2.item3', 'Online')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col3.item3', '6%')}
                        </div>

                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col1.item4', 'Яндекс.Деньги')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col2.item4', 'Online')}
                        </div>
                        <div>
                            {t('PaymentAndConditions.Additional.getPayment.col3.item4', '6%')}
                        </div>

                        <div className='AdditionalCommission__table__columns__line'/>
                    </div>
                    <div className='AdditionalCommission__table__reference'>
                        <p>
                            <span className='blue-star'>*&nbsp;</span>
                            {t('PaymentAndConditions.Additional.getPayment.addInfo1', 'В большинстве случаев перевод осуществляется моментально, но время транзакции может быть увеличено банком-эквайером')}
                        </p>
                    </div>
                    <div className='AdditionalCommission__table__reference'>
                        <p>
                            <span className='blue-star'>**&nbsp;</span>
                            {t('PaymentAndConditions.Additional.getPayment.addInfo2', 'Комиссии приведены справочно и могут быть изменены')}
                        </p>
                    </div>
                </div>

                <div className='AdditionalCommission__table'>
                    <NoteElement img={walletIcon} title={t('PaymentAndConditions.Additional.sendMoney.title', 'Перевод денег продавцу')}>
                        {t('PaymentAndConditions.Additional.sendMoney.subtitle', 'Продавец должен указать реквизиты для получения платежа, при выводе взимается комиссия')}
                    </NoteElement>
                    <ul>
                        <li>
                            <p>
                                {t('PaymentAndConditions.Additional.sendMoney.note1', 'Вывод на банковскую карту, эмитентом которой является банковское учреждение, зарегистрированное и действующее в соответствии с законодательством иного государства, - 3,5%, но не менее 190 рублей РФ')}}
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                {t('PaymentAndConditions.Additional.sendMoney.note2', 'Вывод на банковскую карту, эмитентом которой является банковское учреждение, зарегистрированное и действующее в соответствии с законодательством РФ, - 2%, но не менее 30 рублей РФ\n')}
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                {t('PaymentAndConditions.Additional.sendMoney.note3', 'Вывод на банковскую карту, эмитентом которой является банковское учреждение, зарегистрированное и действующее в соответствии с законодательством Украины, - 3,5%, но не менее 190 рублей РФ')}
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                {t('PaymentAndConditions.Additional.sendMoney.note4', 'Вывод в систему Яндекс.Деньги - 2,5%')}
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                {t('PaymentAndConditions.Additional.sendMoney.note5', 'Вывод в систему QIWI Кошелек - 2,5%')}
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                {t('PaymentAndConditions.Additional.sendMoney.note6', 'Вывод в систему WebMoney Transfer - 3,3%')}
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                {t('PaymentAndConditions.Additional.sendMoney.note7', 'Вывод в систему оператора связи ПАО «Вымпелком», ПАО «МТС», ПАО «МегаФон» - 2%')}
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                    </ul>
                    <div className='AdditionalCommission__table__reference'>
                        <p>
                            <span className='blue-star'>***&nbsp;</span>
                            {t('PaymentAndConditions.Additional.sendMoney.addInfo1', 'Комиссии приведены справочно и могут быть изменены')}
                        </p>
                    </div>
                </div>

                <div className='AdditionalCommission__line'/>
            </div>
        </section>
    )
}