import React, {FC} from "react";
import {SelectElementPropsType} from "../../types";
import cn from "classnames";

export const SelectElement: FC<SelectElementPropsType> = (props) => {

    const {
        handlers,
        selected,
        children,
        element,
        index,
    } = props

    function handleClickElement() {
        handlers.onSelected(element)
        handlers.setSelected(index)
        handlers.setIsFocus(false)
    }

    return (
        <div id={String(index)}
             onClick={handleClickElement}
             data-cy={'select-item-' + String(index)}
             className={cn({
                 "Select__item": true,
                 "Select__item--selected": selected === index,
             })}>{children}</div>
    )
}