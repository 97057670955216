import React, {FC} from "react";
import './Logo.css'
import classNames from "classnames";
import {Common} from "../../../assets/common";

export const Logo: FC<{ black?: boolean }> = ({black}) => {
    return (
        <div className={classNames({
            'Logo': true,
            'Logo--black': black
        })}>
            <div className="Logo__icon"/>
            <div className="Logo__name">
                {Common.Name}
            </div>
        </div>
    )
}