import {IErrors, IErrorsFront} from '../types'

/*
* Обрезает начало строки после ключевого символа
*
* @params { str } - строка в которой будем делать срез
* @params { after } - ключевой символ после которого будет срез
* @params { isTrim } - boolean параметр который отвечает за срез проблем, с начала и конца строки
* @return - строка
* */
export const sliceAfter = (str: string, after: string | RegExp, isTrim: boolean = true): string => {
    const index = str.replace(after, '%%%').indexOf('%%%');
    if (index !== -1) {
        const result = str.slice(index + 1)
        return isTrim ? result.trim() : result
    } else return isTrim ? str.trim() : str
}


/*
* Проходится по массиву ошибок, вернувшегося из Backend
*
* @params { errorsArray } - массив с ошибками
* @return - Возвращает обьект, где "key" поле в форме, a "value" ошибка
* */

type ErrorsArrayType = IErrors | IErrorsFront;

export const MappingErrors = (errorsArray?: ErrorsArrayType[]): object => {
    let ObjectWithErrors: any = {}
    if (errorsArray) {
        errorsArray.map((item: ErrorsArrayType) => {
            if (item.field && item.message) {
                ObjectWithErrors[item.field] = sliceAfter(item.message, /[:]/)
            }
        })
    }
    return ObjectWithErrors
}