import React, {FC, MutableRefObject} from "react";
import './style.css'

type AudienceCardType = {
    BgImage: string,
    title: string,
    text: string,
    refArray: MutableRefObject<(HTMLDivElement | null)[]>,
    index: number
}

export const AudienceCard: FC<AudienceCardType> = (props) => {

    const {
        BgImage,
        text,
        title,
        refArray,
        index
    } = props



    return (
        <div ref={(ref) => { refArray.current[index] = ref }} className='AudienceCard'>
            <div style={{backgroundImage: `url(${BgImage})`}} className='AudienceCard__title'>
                <span>{title}</span>
            </div>
            <div className='AudienceCard__text'>
                {text}
            </div>
        </div>
    )
}