import React, {FC} from "react";
import './style.css'
import {howWorksImages} from "../../../../../assets/images/landings/Pages/HowWorks";
import {NoteElement} from "../../../uiSections/NoteElement";
import {useTranslation} from "react-i18next";
import {commonImages} from "../../../../../assets/images/common";
const {
    arbitrationIcon,
    messageIcon,
    expectingPaymentIcon,
    paidIcon,
    closedIcon,
    deliveredIcon,
    deliveryIcon,
    expectingConfirmIcon
} = commonImages.statusIcons


const {
    arrow
} = howWorksImages

export const Stages: FC = () => {
    const {t} = useTranslation()

    /* data */
    const stageStatusesData = [
        {
            img: expectingConfirmIcon,
            title: t('HowWorks.Stages.item1.title', 'Статус «Ожидает подтверждения»'),
            text: t('HowWorks.Stages.item1.text', 'Статус, образовывающийся после того, как Покупатель или Продавец создали сделку. В этом статусе Продавец или Покупатель должны подтвердить сделку в личном кабинете. В условиях данной сделки есть возможность указать подробные условия, являющиеся обязательными к исполнению одной или обеими сторонами условия доставки, этапы оплаты, качество товаров/услуг, срок проверки или гарантийные обязательства.'),
        },
        {
            img: expectingPaymentIcon,
            title: t('HowWorks.Stages.item2.title', 'Статус «Ожидается оплата»'),
            text: t('HowWorks.Stages.item2.text', 'После подтверждения оплаты, которая была совершена обеими сторонами, сделка переходит в статус «Ожидается оплата». Для совершения каких-либо дальнейших действий Покупателю требуется провести оплату по действующей сделки с  Продавцом. Оплата совершается банковским переводом, банковской картой или электронными деньгами на сайте.'),
        },
        {
            img: paidIcon,
            title: t('HowWorks.Stages.item3.title', 'Статус «Оплачена»'),
            text: t('HowWorks.Stages.item3.text', 'На действующем этапе Продавец обязуется передать товар в доставку или передать услугу с оговоренным и согласованным с Покупателем способом. После отправки товара загрузите скан копии квитанции и код, необходимый для отслеживания доставки в личном кабинете.'),
        },
        {
            img: deliveryIcon,
            title: t('HowWorks.Stages.item4.title', 'Статус «Доставка»'),
            text: t('HowWorks.Stages.item4.text', 'Когда сделка находится в статусе «Доставка», Покупатель может совершить действия: «Принять заказ», «Открыть претензию» или «Продлить срок защиты». Если Покупатель подтверждает, что товар получен и соответствует оговоренным с Продавцом условиям, сделка подлежит закрытию. Если товар был не доставлен или он не соответствует требованиям и заказу покупателя выставляет претензию продавцу. Тогда деньги сохраняются в нашем сервисе до момента разрешения спора.'),
        },
        {
            img: deliveredIcon,
            title: t('HowWorks.Stages.item5.title', 'Статус «Доставлено»'),
            text: t('HowWorks.Stages.item5.text', 'Статус означает, что товар был доставлен Покупателю.'),
        },
        {
            img: messageIcon,
            title: t('HowWorks.Stages.item6.title', 'Статус «Претензия»'),
            text: t('HowWorks.Stages.item6.text', 'Покупатель может на выбор: «Закрыть претензию», «Запросить возврат» либо «Запросить частичный возврат».'),
        },
        {
            img: arbitrationIcon,
            title: t('HowWorks.Stages.item7.title', 'Статус «Арбитраж»'),
            text: t('HowWorks.Stages.item7.text', 'Если за период из 7 дней стороны не пришли к согласованности, они имеют право прибегнуть к помощи опытных и проверенных арбитражных консультантов, которые изучат суть спора и после предложат обеим сторонам имеющиеся компромиссные варианты'),
        },
        {
            img: closedIcon,
            title: t('HowWorks.Stages.item8.title', 'Статус «Закрыта»'),
            text: t('HowWorks.Stages.item8.text', 'Статус «Закрыта» означает то, что сделка была успешно завершена. В случае выполнения обязательств перед покупателем наш сервис переводит денежные средства на указанные реквизиты продавца, при выявленном ненадлежащем исполнении условий сделки - возвращает покупателю. Сделка закрывается.'),
        },
    ]

    return (
        <section className='Stages Grid'>
            <div className='Stages__header'>
                <div className='Stages__description'>
                    <h2 className='SectionTitle'>
                        {t('HowWorks.Stages.title', 'Все этапы сделки')}
                    </h2>
                    <h4>
                        {t('HowWorks.Stages.subtitle', 'Все защищенные сделки имеют определенные статусы в зависимости от этапа. Статусы управляются пользователями или сервисом, согласно заданных условий.')}
                    </h4>
                </div>
                <img src={arrow}/>
            </div>
            <div className='Stages_statuses'>
                {stageStatusesData.map((item, index) => (
                    <NoteElement img={item.img} title={item.title}>
                        {item.text}
                    </NoteElement>
                ))}
                <div className='Stages_statuses__vertical-line'/>
            </div>
        </section>
    )
}