import React, {Fragment} from 'react'
import {hooks, ui} from "../../../ui";
import {methods} from "../../../../api/methods";
import {Thunk} from "../../../../store/DealReducer";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const {Input, Textarea, Button} = ui


export const ArbitrationForm = () => {


    /* hooks */
    const dispatch = useDispatch()
    const [getters, setters] = methods.useSetters()
    const {state, onChange, onSubmit} = hooks.useCallbackForm(getters.errors);
    const {startArbitration} = Thunk(setters)
    const {t} = useTranslation()


    const handleSend = () => {
        if (onSubmit()) {
            dispatch(startArbitration({
                email: state.email.value,
                message: state.message.value,
                fullName: state.fullName.value
            }))
        }
    }


    return (
        <Fragment>
            <div className='Arbitration__form DealFieldsContainer--small'>
                <Input
                    placeholder={t('Deal.Arbitration.fields.name', 'Имя')}
                    labelEqualsPlaceholder
                    id='fullName'
                    onChange={onChange}
                    {...state.fullName}
                />
                <Input
                    labelEqualsPlaceholder
                    placeholder={t('Deal.Arbitration.fields.email', 'Почта')}
                    id='email'
                    onChange={onChange}
                    {...state.email}
                />
                <Textarea
                    labelEqualsPlaceholder
                    placeholder={t('Deal.Arbitration.fields.message', 'Сообщение')}
                    id='message'
                    onChange={onChange}
                    {...state.message}
                />
                <Button onClick={handleSend}>
                    {t('Deal.Arbitration.fields.buttons.send', 'Отправить')}
                </Button>
            </div>
        </Fragment>
    )
}