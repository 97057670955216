import {
    ClientType,
    DealType,
    RedirectType,
    SET_ACS_URL,
    SET_BUYER,
    SET_BUYER_EMAIL,
    SET_COMMISSION,
    SET_DEAL,
    SET_DEAL_ID,
    SET_ORDER_ID,
    SET_PA_REQ,
    SET_REDIRECT,
    SET_REQUEST_ID,
    SET_SELLER,
    SET_SELLER_EMAIL,
    SET_SUM,
    setAcsUrlType,
    setBuyerEmailType,
    setBuyerType,
    setCommissionType,
    setDealIdType,
    setDealType,
    setOrderIdType,
    setPaReqType,
    setRedirectType,
    setRequestIdType,
    setSellerEmailType,
    setSellerType,
    setSumType
} from "../types";

export const setDealId = (id: number | null): setDealIdType => ({
    type: SET_DEAL_ID,
    payload: id
});

export const setSum = (sum: number | null): setSumType => ({
    type: SET_SUM,
    payload: sum
});

export const setSeller = (seller: ClientType): setSellerType => ({
    type: SET_SELLER,
    payload: seller
});

export const setBuyer = (buyer: ClientType): setBuyerType => ({
    type: SET_BUYER,
    payload: buyer
});

export const setSellerEmail = (email: string | null): setSellerEmailType => ({
    type: SET_SELLER_EMAIL,
    payload: email
});

export const setBuyerEmail = (email: string | null): setBuyerEmailType => ({
    type: SET_BUYER_EMAIL,
    payload: email
});

export const setDeal = (deal: DealType | null): setDealType => ({
    type: SET_DEAL,
    payload: deal
});
export const setOrderId = (id: string | null): setOrderIdType => ({
    type: SET_ORDER_ID,
    payload: id
});

export const setRequestId = (id: string | null): setRequestIdType => ({
    type: SET_REQUEST_ID,
    payload: id
});

export const setPaReq = (value: string | null): setPaReqType => ({
    type: SET_PA_REQ,
    payload: value
});

export const setCommission = (commission: number | null): setCommissionType => ({
    type: SET_COMMISSION,
    payload: commission
});

export const setAcsUrl = (url: string | null): setAcsUrlType => ({
    type: SET_ACS_URL,
    payload: url
});

export const setRedirect = (redirect: RedirectType | null): setRedirectType => ({
    type: SET_REDIRECT,
    payload: redirect
});

