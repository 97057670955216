import React from 'react'
import './style.css'
import {Route} from 'react-router-dom';
import {ArbitrationForm} from "./ArbitrationForm";
import {SuccessStatus} from "./SuccessStatus";
import {hooks} from "../../ui";
import {useTranslation} from "react-i18next";
import {routes} from "../../../routes";

export const Arbitration = () => {

    hooks.useScrollToTop()
    const {t} = useTranslation()

    return (
        <section className='Grid DealPage Arbitration'>
            <h2 className='DealPageTitle'>
                {t('Deal.Arbitration.title', 'Написать в арбитраж')}
            </h2>
            <Route exact path={routes.operation.arbitration.root}>
                <ArbitrationForm/>
            </Route>
            <Route path={routes.operation.arbitration.success}>
                <SuccessStatus/>
            </Route>
        </section>
    )
}
