import React, {FC} from 'react'
import success from './success.svg'
import {Trans} from "react-i18next";

export const Success: FC = () => {

    return (
        <div className='Status'>
            <img src={success} alt="#"/>
            <div>
                <Trans i18nKey='Deal.CardConnect.status.success'>
                    Оплата произведена<br/>успешно!
                </Trans>
            </div>
        </div>
    )
}