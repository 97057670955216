import {MappingErrors, sliceAfter} from "./MappingErrors";
import {StatusCases} from "./StatusCases";
import {Responses} from "./Responses";
import {useSetters} from "./useSetters";

export const methods = {
    Responses: Responses,
    useSetters: useSetters,
    StatusCases: StatusCases,
    MappingErrors: MappingErrors,
    sliceAfter: sliceAfter
}