import React, {FC} from "react";
import './style.css'
import {howWorksImages} from "../../../../../assets/images/landings/Pages/HowWorks";
import {useTranslation} from "react-i18next";

const {
    umbrella
} = howWorksImages

export const ProtectionTime:FC = () => {
    const {t} = useTranslation()

    return (
        <section className='ProtectionTime Grid'>
            <div className='ProtectionTime__content'>
                <div className='ProtectionTime__description'>
                    <h2 className='SectionTitle'>
                        {t('HowWorks.ProtectionTime.title', 'Период защиты сделки')}
                    </h2>
                    <h4>
                        {t('HowWorks.ProtectionTime.text', 'Период защиты сделки составляет 21 день с момента отправки товара, если в условиях сделки не указан иной срок. Если в течение периода защиты сделки Покупатель не осуществил действия «Принять Сделку» или «Открыть Претензию», сделка считается завершенной, деньги переводятся лицу, выступающим в качестве Продавца.')}
                    </h4>
                </div>
                <img src={umbrella}/>
            </div>
        </section>
    )
}