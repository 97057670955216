import React, {FC} from 'react'
import './style.css'

type NoteElementType = {
    img: string,
    title: string,
}
export const NoteElement:FC<NoteElementType> = (props) => {
    const {
        img,
        title,
        children
    } = props


    return (
        <div className='NoteElement'>
            <img src={img}/>
            <h3>{title}</h3>
            <p>{children}</p>
        </div>
    )
}