import React, {FC} from "react";
import './style.css'
import classNames from 'classnames'
import {PropsType} from "./types";

export const AddingButton: FC<PropsType> = (props) => {

    /* props */
    const {children, className, variant = 'default', ...all} = props

    /* classes */
    const classes = classNames({
        'AddingButton': true
    }, "AddingButton--" + variant);

    return (
        <button className={classes} {...all}>
            {children}
        </button>
    )
}