import React, {useState} from 'react'
import './style.css'
import {inputParserCreator} from "./helpers";
import {PropsType} from "./types";
import ReactInputMask from "react-input-mask";


//TODO переписать

export const BankCard: React.FC<PropsType> = (props) => {

    const {cardCvv, cardDate, cardNumber} = props

    const [tempCardNumber, setTempCardNumber] = useState('')
    const [tempCardDate, setTempCardDate] = useState('')
    const [tempCardCvv, setTempCardCvv] = useState('')

    const onChangeCardNumber = inputParserCreator({
        length: 17,
        symbol: /\s/g,
        tempSetter: setTempCardNumber,
        setter: cardNumber.setValue,
        periodic: 4
    })

    const onChangeCardDate = inputParserCreator({
        length: 5,
        symbol: '/',
        tempSetter: setTempCardDate,
        setter: cardDate.setValue,
        periodic: 2
    })

    const onChangeCardCvv = inputParserCreator({
        length: 4,
        symbol: '',
        tempSetter: setTempCardCvv,
        setter: cardCvv.setValue,
        periodic: 100
    })


    const ErrorsField = {
        number: (!!props.errors.card_number ? "BankCard__input--error" : ""),
        date: ((!!props.errors.exp_year || !!props.errors.exp_month) ? "BankCard__input--error" : ""),
        cvv: (!!props.errors.cvv ? "BankCard__input--error" : ""),
    }

    return (
        <div className='BankCard'>
            <ReactInputMask
                value={tempCardNumber}
                onChange={onChangeCardNumber}
                className={ErrorsField.number}
                required={props.required}
                maskChar={null}
                placeholder='XXXX XXXX XXXX XXXX'
                mask="9999 9999 9999 9999"
            />
            <div className='BankCard__fields-wrapper'>
                <ReactInputMask
                    value={tempCardDate}
                    onChange={onChangeCardDate}
                    className={ErrorsField.date}
                    required={props.required}
                    placeholder='MM/YY'
                    maskChar={null}
                    mask="99/99"
                />
                <ReactInputMask
                    type="password"
                    value={tempCardCvv}
                    onChange={onChangeCardCvv}
                    className={ErrorsField.cvv}
                    required={props.required}
                    placeholder='CVV'
                    maskChar={null}
                    mask="999"
                />
            </div>
            <div className="BankCard__error">
                {
                    props.errors.card_number
                    || props.errors.exp_year
                    || props.errors.exp_month
                    || props.errors.cvv
                }
            </div>
        </div>
    )
}