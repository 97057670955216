import {ISetters, IErrors} from '../types';


type CallBack = () => void;

export const StatusCases = (setters: ISetters) => {
    return {
        case2XX: (callback?: CallBack) => {
            setters.setLoading(false)
            if (callback) callback()
        },
        case4XX: (error?: IErrors[], callback?: CallBack) => {
            setters.setLoading(false)
            if (callback) callback()
            if (error) {
                setters.setErrors(error as IErrors[])
                error.map(({message}) => setters.setError(message))
            }
        },
        case500: (text: string = 'Внутрення ошибка!', callback?: CallBack) => {
            setters.setLoading(false)
            setters.setError(text)
            if (callback) callback()
        },
        caseDefault: (callback?: () => void) => {
            setters.setLoading(false)
            if (callback) callback()
        }
    }
}