import {useState} from "react";
import {useHistory} from "react-router-dom";
import {IErrors, IErrorsFront, ISetters} from "../types";


interface IGetters {
    loading: boolean
    errors: IErrors[] | IErrorsFront[]
    error: string | null
    data: any
}

type ReturnedType = [IGetters, ISetters]

type PropsType = {
    initialLoading?: boolean
    initialError?: string | null
    initialData?: null | any
    initialErrors?: []
}

export const useSetters = (props: PropsType = {}): ReturnedType => {

    /* initial value */
    const {
        initialLoading = false,
        initialErrors = [],
        initialError = '',
        initialData = null
    } = props;

    /* props */
    const [loading, setLoading] = useState<boolean>(initialLoading)
    const [errors, setErrors] = useState<IErrors[] | IErrorsFront[]>(initialErrors)
    const [error, setError] = useState<string | null>(initialError)
    const [data, setData] = useState<any>(initialData)

    /* hooks */
    const history = useHistory();

    /* objects */
    const getters: IGetters = {
        loading,
        errors,
        error,
        data
    }

    const setters: ISetters = {
        setLoading,
        setErrors,
        setError,
        setData,
        history,
    }

    return [getters, setters]
}