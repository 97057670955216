import React, {FC, Fragment} from "react";
import './style.css'
import {Feedback} from "../../uiSections/Feedback";
import {StartSectionWave} from "../../uiSections/StartSectionWave";
import {Commission} from "./Commission";
import {AdditionalCommission} from "./AdditionalCommission";
import {hooks} from "../../../ui";
import {useTranslation} from "react-i18next";


export const PaymentAndConditions: FC = () => {

    hooks.useScrollToTop()
    const {t} = useTranslation()

    return (
        <Fragment>
            <section className='ServiceRules'>
                <StartSectionWave title={t('PaymentAndConditions.title', 'Оплата и условия')}>
                    {t('PaymentAndConditions.subtitle', 'Мы предлагаем сервис для защиты онлайн сделок купли-продажи для физических и юридических лиц')}
                </StartSectionWave>
                <Commission/>
                <AdditionalCommission/>
            </section>
            <Feedback/>
        </Fragment>
    )
}