import React, {FC} from 'react'
import './style.css'
import {informationImages} from "../../../../assets/images/landings/main";
import {useTranslation} from "react-i18next";

const {
    informationListItem,
} = informationImages

export const FeaturesList: FC = () => {

    const {t} = useTranslation()

    const listData = [
        t('Main.DealInformation.item1', 'продавец и покупатель договорились о безопасной сделке'),
        t('Main.DealInformation.item2', 'покупатель переводит деньги на безопасный счет'),
        t('Main.DealInformation.item3', 'продавец осуществляет передачу товара или услуги покупателю'),
        t('Main.DealInformation.item4', 'покупатель принимает товар, проверяет его соответствие условиям сделки'),
        t('Main.DealInformation.item5', 'наш сервис переводит деньги продавцу и завершает безопасную сделку'),
    ]

    return (
        <ul className='FeaturesList'>
            {listData.map((item, index) => (
                <FeaturesListItem key={index} index={index}>{item}</FeaturesListItem>
            ))}
        </ul>
    )
}

const FeaturesListItem: FC<{ index: number }> = (props) => {
    const {
        children,
        index
    } = props

    return (
        <li className='FeaturesList__item'>
            <img className='FeaturesList__item__img' src={informationListItem}/>
            <span>{index + 1}</span>
            <div className='FeaturesList__item__text'>{children}</div>
            <img className='FeaturesList__item__img' src={informationListItem}/>
        </li>
    )
}