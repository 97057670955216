import React, {FC} from "react";
import './style.css'
import classNames from 'classnames'
import {PropsType} from "./types";
import {Button} from "../Button";

export const ButtonWithAlert: FC<PropsType> = (props) => {

    const {
        alertContent,
        children,
        ...all
    } = props

    return (
        <div className='ButtonWithAlert'>
            <Button {...all}>{children}</Button>
            <div className='ButtonWithAlert__alert'>
                {alertContent}
            </div>
        </div>
    )
}