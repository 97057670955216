import React, {FC, useEffect, useState} from "react";
import './style.css'
import classNames from 'classnames'
import {locationDependencies} from "./locationDependencies";
import {Link, useHistory, useLocation} from 'react-router-dom'
import {Button} from "../ui/Button";
import {Logo} from "./Logo";
import {useTranslation} from "react-i18next";
import {routes} from "../../routes";
import {useClearDeal} from "../Deal/useClearDeal";

export const Header: FC = () => {

    /* hooks */
    const location = useLocation()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const clearDeal = useClearDeal()
    /* state */
    const [isNavOpened, setIsNavOpened] = useState(false)
    const [blackLogo, setBlackLogo] = useState<boolean>(false)
    const [isDropdownOpened, setIsDropdownOpened] = useState(false)

    /* handlers */
    const dropdownToggle = () => setIsDropdownOpened(!isDropdownOpened)
    const navToggle = () => setIsNavOpened(!isNavOpened)
    const handleToCreateDeal = () => {
        clearDeal()
        history.push(routes.operation.creating)
    }

    function handleChangeLang() {
        i18n.changeLanguage(
            i18n.language === 'ru' ? 'en' : 'ru'
        ).finally()
    }

    /* effects */
    useEffect(() => {
        setBlackLogo(
            !locationDependencies.filter(path => path === location.pathname).length
        )
    }, [location])


    /* view methods */
    const getCurrentPage = () => {
        switch (location.pathname) {
            case routes.conditions:
                return t('Header.paymentAndCondition', 'Оплата и условия')
            case routes.serviceRules:
                return t('Header.serviceRules', 'Правила сервиса')
            case routes.howWorks:
                return t('Header.howItWorks', 'Как это работает')
            case routes.solvingConflicts:
                return t('Header.solvingProblems', 'Решение споров')
            default:
                return t('Header.howItWorks', 'Как это работает')
        }
    }

    /* classes */
    const classes = classNames({
        'Header': true,
        'Header--black': blackLogo,
    })
    const dropDownClasses = classNames({
        'Header__nav-item': true,
        'Header__dropdown__wrapper': true,
        'Header__dropdown--active': isDropdownOpened
    });


    return (
        <header className={classes}>
            <Link to='/'>
                <Logo black={blackLogo}/>
            </Link>
            <nav className='Header__desktop-nav'>
                <button onClick={handleChangeLang} className='Header__toggleLang'>
                    <span>RU</span>
                    <span/>
                    <span>EN</span>
                </button>
                {/*<Link className='Header__nav-item' to='/about'>{t('Header.aboutUs', 'О нас')}</Link>*/}
                <div className={dropDownClasses} onClick={dropdownToggle}>
                    <span>{getCurrentPage()}</span>
                    <div className='Header__dropdown'>
                        <Link to={routes.howWorks}>{t('Header.howItWorks', 'Как это работает')}</Link>
                        <Link to={routes.conditions}>{t('Header.paymentAndCondition', 'Оплата и условия')}</Link>
                        <Link to={routes.solvingConflicts}>{t('Header.solvingProblems', 'Решение споров')}</Link>
                        <Link to={routes.serviceRules}>{t('Header.serviceRules', 'Правила сервиса')}</Link>
                    </div>
                </div>
                <Button
                    className='Header__nav-item'
                    onClick={handleToCreateDeal}
                    variant='empty-blue'>{t('Header.button', 'Перевести')}
                </Button>
            </nav>

            <div className='Header__burger' onClick={navToggle}/>
            {isNavOpened && (
                <nav className='Header__mobile-nav'>
                    <button onClick={handleChangeLang} className='Header__toggleLang'>
                        <span>RU</span>
                        <span/>
                        <span>EN</span>
                    </button>
                    {/*<Link className='Header__nav-item' to='/about' onClick={navToggle}>О нас</Link>*/}
                    <div className='Header__nav-item'>
                        <div className={dropDownClasses} onClick={dropdownToggle}>
                            <span>{getCurrentPage()}</span>
                            <div className='Header__dropdown'>
                                <Link to={routes.howWorks} onClick={navToggle}>
                                    {t('Header.howItWorks', 'Как это работает')}
                                </Link>
                                <Link to={routes.conditions} onClick={navToggle}>
                                    {t('Header.paymentAndCondition', 'Оплата и условия')}
                                </Link>
                                <Link to={routes.solvingConflicts} onClick={navToggle}>
                                    {t('Header.solvingProblems', 'Решение споров')}
                                </Link>
                                <Link to={routes.serviceRules} onClick={navToggle}>
                                    {t('Header.serviceRules', 'Правила сервиса')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Link to={routes.operation.creating}>
                        <Button className='Header__nav-item' variant='empty-blue'>
                            {t('Header.button', 'Перевести')}
                        </Button>
                    </Link>
                </nav>
            )}
        </header>
    )
}
