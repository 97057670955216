import {IClientCreate, ISetters} from '../../../api/types'
import {Dispatch} from 'redux'
import {Client, Deal, Feedback} from '../../../api'
import {actions} from '../index'
import {StateType} from '../../types'
import {AxiosResponse} from "axios";
import {routes} from "../../../routes";
import {setBuyer} from "./actions";


interface IFio {
    lastName: string
    firstName: string
    middleName: string
}

interface IGetClient {
    email: string,
    cardNumber?: string
    clientType: "setSeller" | "setBuyer"
    buyerFio: IFio
    sellerFio: IFio
    phone: string,
}

interface IUpdateClient {
    email: string
    clientId: number
    lastName: string
    firstName: string
    middleName: string
    cardNumber?: string
}

interface ICreateDeal {
    name: string
    amount: number
    description: string
    card_number?: string
    sender_city?: string
    sender_address?: string
    sender_country?: string
}

interface IGetDealStatus {
    dealId: number
}

interface IDealApprove {
    dealId: number
    paRes: string
}

interface IStateDeal {
    dealId: number
    cardNumber: number
    expYear: number
    expMonth: number
    cvv: string
    cardHolder: string | null
}

interface IArbitration {
    email: string
    message: string
    fullName: string
}

interface ISetCardNumberForSeller {
    email: string,
    clientId: number,
    lastName: string,
    firstName: string,
    middleName: string,
    cardNumber: string
}

function catchError(errors: any) {
    if (errors) {
        let error = '';
        errors.map(({message}: any) => {
            error += `
                    ${message}
                    `
        })
        return console.error(error)
    }
    return
}

export const DealReducerThunk = (setters: ISetters) => {

    const getClientNew = (props: IGetClient) => async (dispatch: Dispatch<any>, getState: () => StateType) => {

        const response = await Client.get({email: props.email})

        try {
            switch (response.status) {
                case 200: {

                    const {clientType} = props

                    /* response data */
                    const {
                        id,
                        email,
                        phone,
                        last_name,
                        first_name,
                        middle_name,
                    } = response.data.data;


                    const responseFio = {
                        lastName: last_name,
                        firstName: first_name,
                        middleName: middle_name,
                    }
                    /* response data */

                    if (!(
                        Boolean(last_name) &&
                        Boolean(first_name) &&
                        Boolean(middle_name)
                    )) {
                        const {sellerFio, buyerFio} = props;
                        dispatch(updateClientNew({
                            email,
                            phone,
                            clientType,
                            clientId: id,
                            ...(clientType === "setSeller" ? sellerFio : buyerFio)
                        }))
                    }

                    if (phone === null && clientType === "setBuyer") {
                        const {buyerFio} = props
                        dispatch(updateClientNew({
                            email,
                            phone,
                            clientType,
                            clientId: id,
                            ...buyerFio
                        }))
                    }

                    dispatch(actions[clientType]({
                        id, email, ...responseFio
                    }))
                    break
                }
                case 404: {
                    const {phone, email, buyerFio, sellerFio, clientType} = props;
                    if (props.clientType === "setBuyer") {
                        dispatch(createClientNew({
                            email, phone, clientType, ...buyerFio
                        }))
                    } else {
                        dispatch(createClientNew({
                            email, clientType, ...sellerFio
                        }))
                    }
                }
                    break
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break
                default:
                    break
            }
        } catch (e) {
            console.log(JSON.stringify(e.response))
        }
    }


    const createClientNew = (props: IClientCreate & { clientType: "setSeller" | "setBuyer", email: string }) => async (dispatch: Dispatch<any>, getState: () => StateType) => {

        const {clientType, ...data} = props

        const response = await Client.create({...data});

        try {
            switch (response.status) {
                case 201:
                    const {email} = props;
                    const {id, first_name, last_name, middle_name} = response.data.data;
                    dispatch(actions[clientType]({
                        id,
                        email,
                        lastName: last_name,
                        firstName: first_name,
                        middleName: middle_name
                    }))
                    break
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break
                case 404:
                    setters.setErrors(response.data.data.errors)
                    break
                default:
                    break
            }
        } catch (e) {
            console.log(JSON.stringify(e.response))
        }
    }


    const updateClientNew = (props: IUpdateClient & { clientType: "setSeller" | "setBuyer", phone: string }) => async (dispatch: Dispatch<any>, getState: () => StateType) => {

        console.log(
            "updateClientNew - props",
            props
        )
        const response = await Client.update(props);


        try {
            switch (response.status) {
                case 204:
                    const {clientType} = props;

                    const {
                        id,
                        email,
                        last_name: lastName,
                        first_name: firstName,
                        middle_name: middleName
                    } = response.data.data;

                    dispatch(actions[clientType]({
                        id,
                        email,
                        lastName,
                        firstName,
                        middleName
                    }))
                    break
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break
                case 404:
                    setters.setErrors(response.data.data.errors)
                    break
                default:
                    break
            }
        } catch (e) {
            console.log(JSON.stringify(e.response))
        }
    }

    const createDeal = (props: ICreateDeal) => async (dispatch: Dispatch<any>, getState: () => StateType) => {

        const buyerId = Number(getState().DealReducer.buyer?.id);
        const sellerId = Number(getState().DealReducer.seller?.id);

        const response: AxiosResponse<any> = await Deal.create({
            buyerId,
            sellerId,
            name: props.name,
            amount: props.amount,
            description: props.description,
            card_number: props.card_number,
            sender_city: props.sender_city,
            sender_address: props.sender_address,
            sender_country: props.sender_country,
        });

        try {
            switch (response.status) {
                case 201:
                    const {id, amount, seller, buyer} = response.data.data;
                    dispatch(actions.setDealId(id));
                    dispatch(actions.setSum(amount));
                    dispatch(actions.setDeal({
                        id,
                        name: props.name,
                        amount: props.amount,
                        description: props.description,
                    }))
                    setters.history.push(routes.operation.info)
                    break
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log(JSON.stringify(e.response))
        }
    }

    const getDealStatus = (props: IGetDealStatus) => async (dispatch: Dispatch<any>, getState: () => StateType) => {

        const caseError = routes.operation.cardConnect.error;
        const caseSuccess = routes.operation.cardConnect.success;

        const response: AxiosResponse<any> = await Deal.get({dealId: props.dealId})

        if (response.status === 200) {
            switch (response.data.data.status) {
                /* пингуем */
                case "started":
                    dispatch(actions.setRedirect(response.data.data.redirect))
                    setters.setLoading(true)
                    break;
                case "sent_process":
                    setters.setLoading(true)
                    break
                case "new":
                    setters.setLoading(true)
                    break
                case "approved":
                    setters.setLoading(true)
                    break
                /* редирект на страницу ошибки */
                case "rejected":
                    setters.history.push(caseError)
                    setters.setLoading(false)
                    break
                case "error":
                    setters.history.push(caseError)
                    setters.setLoading(false)
                    break
                /* редирект на страницу успеха */
                case "completed":
                    setters.history.push(caseSuccess)
                    setters.setLoading(false)
                    break
                default:
                    setters.setLoading(true)
            }
        } else {
            catchError(response.data.data.errors)
        }
    }


    const getDealStatusAfter3ds = (props: IGetDealStatus) => async (dispatch: Dispatch<any>, getState: () => StateType) => {

        const caseError = routes.operation.cardConnect.error;
        const caseSuccess = routes.operation.cardConnect.success;

        const response: AxiosResponse<any> = await Deal.get({dealId: props.dealId})

        if (response.status === 200) {
            switch (response.data.data.status) {
                /* пингуем */
                case "started":
                    setters.setLoading(true)
                    break;
                case "sent_process":
                    setters.setLoading(true)
                    break
                case "new":
                    setters.setLoading(true)
                    break
                case "approved":
                    setters.setLoading(true)
                    break
                /* редирект на страницу ошибки */
                case "rejected":
                    setters.history.push(caseError)
                    setters.setLoading(false)
                    break
                case "error":
                    setters.history.push(caseError)
                    setters.setLoading(false)
                    break
                /* редирект на страницу успеха */
                case "completed":
                    setters.history.push(caseSuccess)
                    setters.setLoading(false)
                    break
                default:
                    setters.setLoading(true)
            }
        } else {
            setters.history.push(caseError)
            setters.setLoading(false)
        }
    }

    const dealApprove = (props: IDealApprove) => async (dispatch: Dispatch<any>, getState: () => StateType) => {
        const response: AxiosResponse<any> = await Deal.approve({
            dealId: props.dealId,
            paRes: props.paRes
        })
        switch (response.status) {
            case 204:
                // setters.setLoading(false)
                break;
            default:
                setters.setLoading(true)
        }
    }

    const startDeal = (props: IStateDeal) => async (dispatch: Dispatch<any>, getState: () => StateType) => {
        const response: AxiosResponse<any> = await Deal.start({
            cardHolder: props.cardHolder,
            cardNumber: props.cardNumber,
            expMonth: props.expMonth,
            expYear: props.expYear,
            dealId: props.dealId,
            cvv: props.cvv
        });
        switch (response.status) {
            case 204:
                setters.history.push(routes.operation.cardConnect.loading)
                setters.setLoading(true)
                break;
            case 400:
                setters.setErrors(response.data.data.errors)
                setters.setLoading(false)
                break
            default:
                break;
        }
    }

    const startArbitration = (props: IArbitration) => async (dispatch: Dispatch<any>, getState: () => StateType) => {
        const response: AxiosResponse<any> = await Feedback.post({
            email: props.email,
            message: props.message,
            fullName: props.fullName
        });
        switch (response.status) {
            case 200:
                setters.setData(true)
                break;
            case 400:
                setters.setErrors(response.data.data.errors)
                break;
            default:
                break;
        }
    }

    return {
        createDeal,
        getDealStatus,
        getDealStatusAfter3ds,
        dealApprove,
        startDeal,
        startArbitration,

        /* new */
        getClientNew,
        createClientNew,
        updateClientNew,
    }
}
