import React, {FC} from "react";
import './style.css'
import {serviceBenefitsImages} from "../../../../assets/images/landings/main";
import {useTranslation} from "react-i18next";

const {rocket} = serviceBenefitsImages;

export const ServiceBenefits: FC = () => {
    const {t} = useTranslation()

    return (
        <section className='Main__ServiceBenefits Grid'>
            <div className='SectionTitle'>
                {t('Main.ServiceBenefits.title', 'Преимущества нашего сервиса')}
            </div>
            <img src={rocket} alt="Изображение ракеты"/>
            <ul className='Main__ServiceBenefits__benefits'>
                <li>{t('Main.ServiceBenefits.benefit1',
                    'Независимая третья сторона')}
                </li>
                <li>{t('Main.ServiceBenefits.benefit2',
                    'Финансовые транзакции через счет в банке')}
                </li>
                <li>{t('Main.ServiceBenefits.benefit3',
                    'Уверенность, спокойствие и безопасность')}
                </li>

                <li>{t('Main.ServiceBenefits.benefit4',
                    'Простое урегулирование спорных ситуаций между продавцом и покупателем')}
                </li>
                <li>{t('Main.ServiceBenefits.benefit5',
                    'Гарантия выполнения обязательств (покупатель оплатит, а продавец выполнит условия)')}
                </li>
                <li>{t('Main.ServiceBenefits.benefit6',
                    'Возможность проводить защищенные платежи между физическими лицами')}
                </li>

                <li>{t('Main.ServiceBenefits.benefit7',
                    'Снижение рисков мошенничества и подлога при покупках в Интернете')}
                </li>
                <li>{t('Main.ServiceBenefits.benefit8',
                    'Защиту сделок купли-продажи 24/7 без похода к юристу и в банк')}
                </li>
                <li>{t('Main.ServiceBenefits.benefit9',
                    'Эксперты в области финансовых технологий')}
                </li>
            </ul>
        </section>
    )
}