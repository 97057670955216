import React, {FC, Fragment} from "react";
import './style.css'
import {finalSectionImages} from "../../../../assets/images/landings/main";
import {Feedback} from "../../uiSections/Feedback";
import {useTranslation} from "react-i18next";

const {
    partnerIcon1,
    partnerIcon2,
    partnerIcon3,
    partnerIcon4,
    partnerIcon5
} = finalSectionImages


export const FinalSection: FC = () => {
    const {t} = useTranslation()

    return (
        <Fragment>
            <section className='Main__FinalSection Grid'>
                <div className='Main__FinalSection__title'>
                    {t('Main.FinalSection.title', 'Нашим сервисом уже пользуются')}
                </div>
                <div className='Main__FinalSection__partners'>
                    <img src={partnerIcon1} alt='Лого партнера'/>
                    <img src={partnerIcon2} alt='Лого партнера'/>
                    <img src={partnerIcon3} alt='Лого партнера'/>
                    <img src={partnerIcon4} alt='Лого партнера'/>
                    <img src={partnerIcon5} alt='Лого партнера'/>
                </div>
            </section>
            <Feedback/>
        </Fragment>
    )
}