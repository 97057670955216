import React from 'react'
import './style.css'
import {PropsType} from "./types";
import classNames from "classnames";

export const Textarea: React.FC<PropsType> = (props) => {

    const {error, label, ...all} = props

    const classes = classNames({
        'Textarea': true,
        'Textarea--error': !!error,
        'Textarea--filled': !!props.value,
    })
    return (
        <div className={classes}>
            <textarea {...all}/>
            <label className='Textarea__label'>{label}</label>
            {error && <span className='Textarea__error'>{error}</span>}
        </div>
    )
}