import React, {FC, useContext} from "react";
import './style.css'
import {informationImages} from "../../../../assets/images/landings/main";
import {InformationList} from "./InformationList";
import {MediaQueryContext} from "../../../../contexts/MediaQueryContext";
import {useTranslation} from "react-i18next";

const {
    shieldImage
} = informationImages
export const DealInformation: FC = () => {
    const media = useContext(MediaQueryContext)
    const {t} = useTranslation()

    return (
        <section className='Main__DealInformation Grid'>
            <div className='Main__DealInformation__left'>
                <h2 className='SectionTitle'>
                    {t('Main.DealInformation.title', 'Что такое безопасная сделка?')}
                </h2>
                <h4>
                    {t('Main.DealInformation.text', 'Безопасная сделка – сервис защиты покупателя и продавца в интернете. Безопасная сделка гарантирует 100% предоплату, которая хранится на нашем счёте до тех пор пока продавец не выполнит все свои обязательства, а покупатель не получит товар или услугу. Также безопасная сделка обеспечивает сохранность средств покупателя: если продавец нарушает условия сделки, наш сервис возвращает средства или предлагает покупателю скидку.')}
                </h4>
            </div>
            <img className='Main__DealInformation__right' src={shieldImage[media]} alt='Дверь банковского сейфа'/>
            <InformationList/>
        </section>
    )
}