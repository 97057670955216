import {useEffect} from "react";

export const useScrollToError = (deps: any[], errorClass: string = '.Input--error') => {

    let errorInput = window.document.querySelector(errorClass)

    const handleSubmit = () => {
        if (errorInput !== null) {
            errorInput.scrollIntoView({behavior: "smooth"})
        }
    }
    useEffect(() => handleSubmit(), [...deps, errorInput])

    return handleSubmit;
};