import React, {Fragment} from 'react'
import './style.css'
import {hooks, ui} from "../../ui";
import {selectors} from "../../../store/DealReducer";
import {useSelector} from "react-redux";
import {ButtonWithAlert} from "../../ui/ButtonWithAlert";
import {useHistory} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {routes} from "../../../routes";

const {
    Button,
    AlertContainer,
    InformationBlock,
} = ui;

export const InformationOfDeal = () => {


    /* hooks */
    hooks.useScrollToTop()
    const history = useHistory()

    const sumFromStore = useSelector(selectors.getSum)
    const sellerFromStore = useSelector(selectors.getSeller)
    const buyerFromStore = useSelector(selectors.getBuyer)
    const dealFromStore = useSelector(selectors.getDeal)
    const {t} = useTranslation()

    /* state */

    /* vars */
    const BuyerFullName = {
        email: buyerFromStore?.email || "",
        lastName: buyerFromStore?.lastName || "",
        firstName: buyerFromStore?.firstName || "",
        middleName: buyerFromStore?.middleName || "",
        isFio: (
            buyerFromStore?.lastName
            && buyerFromStore?.firstName
            && buyerFromStore?.middleName
        )
    }

    const SellerFullName = {
        email: sellerFromStore?.email || "",
        lastName: sellerFromStore?.lastName || "",
        firstName: sellerFromStore?.firstName || "",
        middleName: sellerFromStore?.middleName || "",
        isFio: (
            sellerFromStore?.lastName
            && sellerFromStore?.firstName
            && sellerFromStore?.middleName
        )
    }

    const handleSendArbitration = () => history.push(routes.operation.arbitration.root)
    const handleCardConnect = () => history.push(routes.operation.cardConnect.root)


    return (
        <section className='DealPage InformationOfDeal Grid'>
            <h2 className='DealPageTitle'>
                {t('Deal.InformationOfDeal.title', 'Информация по сделке')}
            </h2>
            <AlertContainer>
                <h3>{dealFromStore?.name || ""}</h3>
                <h5>{dealFromStore?.description || ""}</h5>
            </AlertContainer>
            <div className='DealFieldsContainer--small InformationOfDeal__info'>
                <div className='InformationOfDeal__info__user'>
                    <div><span>
                        {t('Deal.InformationOfDeal.buyer.title', 'Покупатель')}
                    </span></div>
                    <InformationBlock label={t('Deal.InformationOfDeal.buyer.fullName', 'ФИО:')}>
                        {BuyerFullName.isFio
                            ? `${BuyerFullName.middleName} ${BuyerFullName.firstName} ${BuyerFullName.lastName}`
                            : t('Deal.InformationOfDeal.buyer.fullNameError', 'Не заполнено! Свяжитесь с покупателем и попросите заполнить данные.')
                        }
                    </InformationBlock>
                    <InformationBlock label='E-mail:'>
                        {BuyerFullName.email}
                    </InformationBlock>
                </div>
                <div className='InformationOfDeal__info__user'>
                    <div style={{display: "flex"}}>
                        <span>
                            {t('Deal.InformationOfDeal.seller.title', 'Продавец')}
                        </span>
                    </div>
                    <div className='InformationOfDeal__info__merchant__blocked'>
                        <InformationBlock label={t('Deal.InformationOfDeal.seller.fullName', 'ФИО:')}>
                            {SellerFullName.isFio
                                ? `${SellerFullName.middleName} ${SellerFullName.firstName} ${SellerFullName.lastName}`
                                : t('Deal.InformationOfDeal.seller.fullNameError', 'Не заполнено! Свяжитесь с продавцом и попросите заполнить данные.')
                            }
                        </InformationBlock>
                        <InformationBlock label='E-mail:'>
                            {SellerFullName.email}
                        </InformationBlock>
                    </div>
                </div>
            </div>
            <div className='DealFieldsContainer--small InformationOfDeal__confirm'>
                <h4>{t('Deal.InformationOfDeal.confirm.amount', 'Сумма для получения:')}<br/>{sumFromStore} ₽</h4>
                <ButtonWithAlert variant={"green"} onClick={handleCardConnect} alertContent={(<Fragment>
                    <p>
                        <Trans i18nKey='Deal.InformationOfDeal.confirm.conditions'>
                            Нажимая кнопку "Привязать карту" я соглашаюсь с условиями<a
                            href='/public-offer.docx' target="_blank"> оферты</a>
                        </Trans>
                    </p>
                </Fragment>)}>
                    {t('Deal.InformationOfDeal.confirm.connectCard', 'Привязать карту')}
                </ButtonWithAlert>
                <Button variant='empty-blue' onClick={handleSendArbitration}>
                    {t('Deal.InformationOfDeal.confirm.writeToArbitration', 'Написать в арбитраж')}
                </Button>
            </div>

            <div className='InformationOfDeal__deal-events'>
                <h3>
                    {t('Deal.InformationOfDeal.dealEvents.title', 'События по сделке')}
                </h3>
                <div className='InformationOfDeal__deal-events__event'>
                    <b>{t('Deal.InformationOfDeal.dealEvents.dateTitle', 'Дата')}</b>
                    <div>
                        {t('Deal.InformationOfDeal.dealEvents.date', '25.09.2020, 11:57')}
                    </div>
                </div>
                <div className='InformationOfDeal__deal-events__event'>
                    <b>
                        {t('Deal.InformationOfDeal.dealEvents.descriptionTitle', 'Описание')}
                    </b>
                    <div>
                        {t('Deal.InformationOfDeal.dealEvents.dealStatus', 'Сделка успешно создана.')}
                    </div>
                </div>
            </div>
        </section>
    )
}
