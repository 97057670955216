import React, {useEffect} from 'react';
import './App.css';
import './components/ui/style/main.css'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {Main} from "./components/landings/Main";
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {useMediaQuery} from "./components/ui/hooks/useMediaQuery";
import {MediaQueryContext} from "./contexts/MediaQueryContext";
import {Deal} from "./components/Deal";
import {About} from "./components/landings/Pages/About";
import {PaymentAndConditions} from "./components/landings/Pages/PaymentAndConditions";
import {ServiceRules} from "./components/landings/Pages/ServiceRules";
import {HowWorks} from "./components/landings/Pages/HowWorks";
import {SolvingConflicts} from "./components/landings/Pages/SolvingConflicts";
import {Common} from "./assets/common";
import {routes} from "./routes";

function App() {

    const media = useMediaQuery();

    useEffect(() => {
        window.document.title = Common.Name
    }, []);


    return (
        <BrowserRouter>
            <MediaQueryContext.Provider value={media}>
                <div className="App">
                    <Header/>
                    <Switch>
                        <Route exact path={routes.root}>
                            <Main/>
                        </Route>
                        {/*<Route exact path={routes.about}>*/}
                        {/*    <About/>*/}
                        {/*</Route>*/}
                        <Route exact path={routes.conditions}>
                            <PaymentAndConditions/>
                        </Route>
                        <Route exact path={routes.serviceRules}>
                            <ServiceRules/>
                        </Route>
                        <Route exact path={routes.howWorks}>
                            <HowWorks/>
                        </Route>
                        <Route exact path={routes.solvingConflicts}>
                            <SolvingConflicts/>
                        </Route>
                        <Route path={routes.operation.root}>
                            <Deal/>
                        </Route>
                    </Switch>
                    <Footer/>
                </div>
            </MediaQueryContext.Provider>
        </BrowserRouter>
    );
}

export default App;
