import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import ReduxThunk from 'redux-thunk';


/* Reducers */
import {DealReducer} from './DealReducer'

const rootReducer = combineReducers({
    DealReducer: DealReducer
})


export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));
