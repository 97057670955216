import {ChangeEvent} from "react";
import {inputParserCreatorPropsType, inputParserCreatorType} from "../types";

export const inputParserCreator = (props: inputParserCreatorPropsType): inputParserCreatorType => {
    const {
        length,
        symbol,
        periodic,
        setter,
        tempSetter
    } = props

    return (event: ChangeEvent<HTMLInputElement>) => {
        // clear value
        let tempValue = Array.from(event.target.value
            .replace(symbol, '')
            .replace(/\s+/g, '')
        )
        if (tempValue.length == length) return
        // generation with support symbol
        let newValue = '';
        tempValue.map((item, index) => {
            newValue += (index && index % periodic == 0)
                ? `${typeof symbol == "string" ? symbol : ' '}${item}`
                : item;
        })

        // set to View and To DAL
        tempSetter(newValue)

        newValue = newValue
            .replace(symbol, '')
            .replace(/\s+/g, '')
        setter(newValue)
    }
}
