import React, {Fragment, useEffect, useState} from 'react'
import './style.css'
import {selectors, Thunk} from "../../../store/DealReducer";
import {methods} from "../../../api/methods";
import {hooks, ui} from "../../ui";
import {paySystemsImages} from "./images";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "./Statuses/loading";
import {Success} from "./Statuses/Success";
import {Error} from "./Statuses/Error";
import {Link, Route, useLocation} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {RedirectForm} from "../RedirectFrom";
import {routes} from "../../../routes";


const {BankCard, AlertContainer, Button} = ui

export const CardConnect = () => {

    /* hooks */
    hooks.useScrollToTop()
    const dispatch = useDispatch();
    const location = useLocation();
    const amount = useSelector(selectors.getSum)
    const dealId = useSelector(selectors.getDealId)
    const redirect = useSelector(selectors.getRedirect)


    const [getters, setters] = methods.useSetters();
    const bankCard = hooks.useBankCardField();

    const [requiredCardField, setRequiredCardField] = useState<boolean>(false)
    const [errorsObject, setErrorsObject] = useState<any>({})
    const {t} = useTranslation()


    /* mapping Errors */
    useEffect(() => {
        setErrorsObject(methods.MappingErrors(getters.errors))
    }, [getters.errors])

    /* DAL */
    const thunk = Thunk(setters);
    const handlePicCard = () => {
        if (
            bankCard.cardNumber.value.length === 16
            && bankCard.cardDate.value.length === 4
            && bankCard.cardCvv.value.length === 3
        ) {
            const number = Number(bankCard.cardNumber.value)
            const year = (Number(String(new Date().getFullYear()).slice(0, 2) + bankCard.cardDate.value.slice(2)))
            const month = Number(bankCard.cardDate.value.slice(0, 2))
            const cvv = String(bankCard.cardCvv.value)
            dispatch(thunk.startDeal({
                dealId: Number(dealId),
                cvv: cvv,
                cardNumber: number,
                expYear: year,
                expMonth: month,
                cardHolder: null,
            }))
        } else {
            setRequiredCardField(true)
        }
    };

    const ButtonRedirect = () => {
        switch (location.pathname) {
            case routes.operation.cardConnect.success:
                return <Link to="/"><Button variant='empty-blue'>
                    {t('Deal.CardConnect.buttons.backToMain', 'На главную')}
                </Button></Link>
            default:
                return <Link to={routes.operation.creating}><Button variant='empty-blue'>
                    {t('Deal.CardConnect.buttons.back', 'Назад')}
                </Button></Link>
        }
    }


    return (
        <section className='CardConnect Grid DealPage'>
            {redirect !== null && (
                <RedirectForm
                    action={redirect.url}
                    method={redirect.method}
                    params={redirect.params}
                />
            )}
            <AlertContainer className='DealFieldsContainer--small'>
                <h3>{t('Deal.CardConnect.paymentPyCard', 'Оплата картой')}</h3>
                <Route exact path={routes.operation.cardConnect.root}>
                    <Fragment>
                        <BankCard
                            errors={{
                                card_number: errorsObject.card_number,
                                exp_month: errorsObject.exp_month,
                                exp_year: errorsObject.exp_year,
                                cvv: errorsObject.cvv,
                            }}
                            required={requiredCardField}
                            {...bankCard}
                        />
                        <span>{Number(amount).toFixed(2)} ₽</span>
                        <Button onClick={handlePicCard}>
                            {t('Deal.CardConnect.buttons.pay', 'Оплатить')}
                        </Button>
                    </Fragment>
                </Route>
                <Route path={routes.operation.cardConnect.loading}>
                    <Loading/>
                </Route>
                <Route path={routes.operation.cardConnect.success}>
                    <Success/>
                </Route>
                <Route path={routes.operation.cardConnect.error}>
                    <Error/>
                </Route>
                <div className='CardConnect__pay-systems'>
                    <img src={paySystemsImages[0]} alt="visa"/>
                    <img src={paySystemsImages[1]} alt="mastercard"/>
                    <img src={paySystemsImages[2]} alt="mir"/>
                    <img src={paySystemsImages[3]} alt="pci"/>
                </div>
            </AlertContainer>
            <div className='CardConnect__confirm'>
                <ButtonRedirect/>
                <Link to="/?support=true">
                    <Button variant='empty-black'>
                        {t('Deal.CardConnect.buttons.techSupport', 'Техническая поддержка')}
                    </Button>
                </Link>
            </div>
        </section>
    )
}
