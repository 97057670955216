import useMedia from "use-media";

type useMediaQueryType = 'mobile' | 'tablet_2' | 'tablet_1' | 'laptop' | 'desktop'


export const useMediaQuery = ():useMediaQueryType => {
    const desktop = useMedia({minWidth: 1920})
    const laptop = useMedia({maxWidth: 1919})
    const tablet_1 = useMedia({maxWidth: 1439})
    const tablet_2 = useMedia({maxWidth: 1023})
    const mobile = useMedia({maxWidth: 719})

    if (mobile) return 'mobile'
    if (tablet_2) return 'tablet_2'
    if (tablet_1) return 'tablet_1'
    if (laptop) return 'laptop'

    return 'desktop'
}