import React, {FC} from "react";
import './style.css'
import classNames from 'classnames'
import {PropsType} from "./types";

export const Button: FC<PropsType> = (props) => {

    /* props */
    const {children, variant = 'blue', className, ...all} = props


    /* classes */
    const classes = classNames(`Button--${variant}`, className, {
        'Button': true,
    });

    return (
        <button className={classes} {...all}>
            {children}
        </button>
    )
}