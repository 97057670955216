import React, {Fragment, useEffect, useState} from 'react'
import './style.css'
import './assetsCss/infoCard.css'
import {informationImages} from "../../../assets/images/landings/main";
import {hooks, ui} from "../../ui";
import {selectors, Thunk} from "../../../store/DealReducer";
import {methods} from "../../../api/methods";
import {useDispatch, useSelector} from "react-redux";
import {ButtonWithAlert} from "../../ui/ButtonWithAlert";
import {Link} from "react-router-dom";
import {IErrorsFront} from "../../../api/types";
import {Trans, useTranslation} from "react-i18next";
import countriesRu from './countries/ru.json'
import countriesEn from './countries/en.json'
import {useCommission} from "../useCommission";
import {Select} from "../../ui/NewSelect/Select";
import {useSelect} from "../../ui/NewSelect/Select/hooks/useSelect";

const {informationListItem} = informationImages
const {
    InformationBlock,
    AlertContainer,
    AddingButton,
    Textarea,
    Input,
    Rub
} = ui;


export const CreatingDeal = () => {


    /* hooks */
    hooks.useScrollToTop()
    const dispatch = useDispatch()
    const [getters, setters] = methods.useSetters()
    const sellerEmailFromStore = useSelector(selectors.getSellerEmail)
    const commission = useSelector(selectors.getCommission)
    const sumFromStore = useSelector(selectors.getSum)
    const buyerFromStore = useSelector(selectors.getBuyer)
    const dealIdFromStore = useSelector(selectors.getDealId)
    const sellerFromStore = useSelector(selectors.getSeller)
    const {t, i18n} = useTranslation()


    /* mapping error */
    const [errorsObject, setErrorsObject] = React.useState<any>({})

    useEffect(() => {
        if (getters.errors.length) {
            setErrorsObject(methods.MappingErrors(getters.errors))
        }
    }, [getters.errors])


    /* state */
    const [fullNameBuyer, setFullNameBuyer] = React.useState<boolean>(false)
    const [fullNameSeller, setFullNameSeller] = React.useState<boolean>(false)

    const onSubmit = hooks.useScrollToError([errorsObject]);

    /* methods */
    const toggleFullNameSeller = () => setFullNameSeller(!fullNameSeller)
    const toggleFullNameBuyer = () => setFullNameBuyer(!fullNameBuyer)

    const name = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.nameFiled', 'Наименование'),
        error: errorsObject.name
    })
    const description = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.descriptionField', 'Описание'),
        error: errorsObject.description
    })

    /* full name client */
    /* seller */
    const sellerLastName = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.sellerLastName', 'Фамилия продавца')
    })
    const sellerFirstName = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.sellerName', 'Имя продавца')
    })
    const sellerMiddleName = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.sellerMiddleName', 'Отчество продавца')
    })

    const [cardNumberSeller, setCardNumberSeller] = useState<string>('')

    const buyerLastName = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.buyerLastName', 'Фамилия покупателя')
    })
    const buyerFirstName = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.buyerName', 'Имя покупателя')
    })
    const buyerMiddleName = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.buyerMiddleName', 'Отчество покупателя')
    })


    const buyerEmail = hooks.useField({
        initial: '',
        placeholder: t('Deal.CreatingDeal.buyerEmail', 'Email покупателя'),
        error: errorsObject.email_buyer,
    })
    const sellerEmail = hooks.useField({
        initial: String(sellerEmailFromStore || ''),
        placeholder: t('Deal.CreatingDeal.sellerEmail', 'Email продавца'),
        error: errorsObject.email_seller
    })
    const amount = hooks.useField({
        initial: String(sumFromStore || ''),
        placeholder: t('Deal.CreatingDeal.sum', 'Сумма'),
        mask: "9".repeat(10),
        error: errorsObject.amount,
    })
    const country = useSelect({
        listElements: i18n.language == 'ru' ? countriesRu : countriesEn
    })
    const phone = hooks.useField({
        placeholder: t('Deal.CreatingDeal.phoneField', '+7'),
        error: errorsObject.phone
    })
    const address = hooks.useField({
        placeholder: t('Deal.CreatingDeal.addressField', t('Deal.CreatingDeal.addressField', 'Адрес')),
        error: errorsObject.address
    })
    const city = hooks.useField({
        placeholder: t('Deal.CreatingDeal.cityField', t('Deal.CreatingDeal.cityField', 'Город')),
        error: errorsObject.city
    })

    /* FIO */
    const sellerFio = {
        lastName: sellerLastName.value,
        firstName: sellerFirstName.value,
        middleName: sellerMiddleName.value,
    }

    const buyerFio = {
        lastName: buyerLastName.value,
        firstName: buyerFirstName.value,
        middleName: buyerMiddleName.value,
    }

    /* DAL */
    const {getClientNew, createDeal} = Thunk(setters)
    const handleSendData = () => {

        onSubmit();

        const validation: IErrorsFront[] = [
            {
                field: 'email_seller',
                message: t('Deal.CreatingDeal.sellerEmailError', 'Введите E-mail продавца'),
                valid: !!sellerEmail.value
            },
            {
                field: 'email_buyer',
                message: t('Deal.CreatingDeal.buyerEmailError', 'Введите E-mail покупателя'),
                valid: !!buyerEmail.value
            },
            {
                field: 'name',
                message: t('Deal.CreatingDeal.nameError', 'Введите имя'),
                valid: !!name.value
            },
            {
                field: 'amount',
                message: t('Deal.CreatingDeal.amountError', 'Введите сумму'),
                valid: !!amount.value
            },
            {
                field: 'description',
                message: t('Deal.CreatingDeal.descriptionError', 'Введите описание'),
                valid: !!description.value
            },
            {
                field: 'card_number',
                message: t('Deal.CreatingDeal.cardNumberError', 'Введите номер карты продавца'),
                valid: cardNumberSeller.length === 19
            },
            {
                field: 'city',
                message: t('Deal.CreatingDeal.cityFieldError', 'Укажите город'),
                valid: !!city.value
            },
            {
                field: 'address',
                message: t('Deal.CreatingDeal.addressFieldError', 'Укажите адрес'),
                valid: !!address.value
            },
            {
                field: 'phone',
                message: t('Deal.CreatingDeal.phoneNumberError', 'Введите телефон'),
                valid: !!phone.value
            },
        ]

        if (
            amount.value
            && name.value
            && buyerEmail.value
            && sellerEmail.value
            && description.value
            && cardNumberSeller.length === 19
        ) {
            dispatch(getClientNew({
                email: sellerEmail.value,
                cardNumber: cardNumberSeller,
                clientType: 'setSeller',
                sellerFio: sellerFio,
                buyerFio: buyerFio,
                phone: phone.value
            }))
        } else {
            setters.setErrors(validation.filter((item) => !item.valid))
        }
    }

    useEffect(() => {
        if (buyerFromStore?.id === null && sellerFromStore?.id !== null) {
            dispatch(getClientNew({
                email: buyerEmail.value,
                cardNumber: cardNumberSeller,
                clientType: 'setBuyer',
                sellerFio: sellerFio,
                buyerFio: buyerFio,
                phone: phone.value
            }))
        }
    }, [sellerFromStore])

    useEffect(() => {
        if (
            dealIdFromStore === null
            && buyerFromStore?.id !== null
            && sellerFromStore?.id !== null
        ) {
            dispatch(createDeal({
                name: name.value,
                amount: Number(amount.value),
                card_number: cardNumberSeller,
                description: description.value,
                sender_city: city.value,
                sender_address: address.value,
                sender_country: country.selected?.value,
            }))
        }
    }, [
        dealIdFromStore,
        sellerFromStore,
        buyerFromStore
    ])


    const calcCommission = useCommission({
        commission: Number(process.env.REACT_APP_COMMISSION),
        minimal: Number(process.env.REACT_APP_MINIMAL_AMOUNT),
        amount: Number(amount.value)
    })

    return (
        <section className='DealPage CreatingDeal Grid'>
            <h2 className='DealPageTitle'>
                {t('Deal.CreatingDeal.title', 'Создание сделки')}
            </h2>
            <AlertContainer>
                <p>
                    {t('Deal.CreatingDeal.description', 'Инициатор сделки должен заполнить данные о товаре или услуге и способе доставки, а так же информацию о себе. При этом инициатору сделки необходимо выбрать свою роль (Продавец или Покупатель). Далее необходимо пригласить второго участника в сделку путем передачи уникальной ссылки (по электронной почте). Второй участник так же должен заполнить информацию о себе. После заполнения данных, каждый участник должен подтвердить, что согласен с условиями сделки.')}
                </p>
            </AlertContainer>
            <div className='CreatingDeal__metadata DealFieldsContainer--large'>
                <Input labelEqualsPlaceholder {...name}/>
                <div className='CreatingDeal__condition'>
                    <img className='CreatingDeal__condition__img' src={informationListItem} alt="#"/>
                    <div className='CreatingDeal__condition__text'>
                        {t('Deal.CreatingDeal.conditionText', 'Важные условия сделки: укажите как можно подробнее характеристики продаваемого товара. Эта информация будет использоваться при возникновении спорных ситуаций.')}
                    </div>
                    <img className='CreatingDeal__condition__img' src={informationListItem} alt="#"/>
                </div>
                <Textarea label={t('Deal.CreatingDeal.descriptionField', 'Описание')} maxLength={125} {...description}/>
                <Select
                    {...country}
                    placeholder={t('Deal.CreatingDeal.countryField')}
                />
                <Input labelEqualsPlaceholder{...city}/>
                <Input labelEqualsPlaceholder{...address}/>
                <Input{...phone} mask='+7 (999) 999 99 99'/>
                <Input support={<Rub/>} labelEqualsPlaceholder {...amount}/>
            </div>
            <div className='CreatingDeal__information DealFieldsContainer--large'>
                <InformationBlock label={t('Deal.CreatingDeal.sellerReceives', 'Продавец получает:')}>
                    {calcCommission.amountSeller} <Rub size={14}/>
                </InformationBlock>
                <InformationBlock label={t('Deal.CreatingDeal.buyerPays', 'Покупатель оплачивает:')}>
                    {calcCommission.amount} <Rub size={14}/>
                </InformationBlock>
                <InformationBlock label={t('Deal.CreatingDeal.commission', 'Комиссия за сделку:')}>
                    {calcCommission.calculations} <Rub size={14}/>
                </InformationBlock>
            </div>
            <div className='CreatingDeal__user-data DealFieldsContainer--large'>
                <div className='CreatingDeal__user-data__content DealFieldsContainer--large'>
                    <Input labelEqualsPlaceholder {...buyerEmail}/>
                    <AddingButton onClick={toggleFullNameBuyer} variant='icon'>
                        {t('Deal.CreatingDeal.buyerFullName', 'Добавить ФИО покупателя')}
                    </AddingButton>
                    {fullNameBuyer && (
                        <div className='CreatingDeal__user-data__fio-form'>
                            <Input labelEqualsPlaceholder {...buyerLastName}/>
                            <Input labelEqualsPlaceholder {...buyerFirstName}/>
                            <Input labelEqualsPlaceholder {...buyerMiddleName}/>
                        </div>
                    )}
                </div>
                <div className='CreatingDeal__user-data__content DealFieldsContainer--large'>
                    <Input labelEqualsPlaceholder {...sellerEmail}/>
                    <AddingButton onClick={toggleFullNameSeller} variant='icon'>
                        {t('Deal.CreatingDeal.sellerFullName', 'Добавить ФИО продавца')}
                    </AddingButton>
                    {fullNameSeller && (
                        <div className='CreatingDeal__user-data__fio-form'>
                            <Input labelEqualsPlaceholder  {...sellerLastName}/>
                            <Input labelEqualsPlaceholder  {...sellerFirstName}/>
                            <Input labelEqualsPlaceholder  {...sellerMiddleName}/>
                        </div>
                    )}
                </div>
            </div>
            <div className='CreatingDeal__confirm DealFieldsContainer--large'>
                <Input
                    labelEqualsPlaceholder
                    placeholder={t('Deal.CreatingDeal.cardNumber', 'Номер карты продавца')}
                    mask='9999 9999 9999 9999'
                    value={cardNumberSeller}
                    error={errorsObject.card_number}
                    onChange={(e) => setCardNumberSeller(e.target.value)}
                />
                <ButtonWithAlert
                    onClick={handleSendData}
                    alertContent={(<Fragment>
                        <Trans i18nKey='Deal.CreatingDeal.serviceRulesAlert'>
                            <p>Продолжая, вы соглашаетесь с <Link to='/service-rules'>правилами</Link> сервиса</p>
                        </Trans>
                    </Fragment>)}>
                    {t('Deal.CreatingDeal.createDeal', 'Создать перевод')}
                </ButtonWithAlert>
            </div>
        </section>
    )
}
