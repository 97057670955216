import React from 'react'
import './style.css'
import './cssAssets/feautre.css'
import {useTranslation} from "react-i18next";

export const Commission = () => {
    const {t} = useTranslation()

    return (
        <section className='Commission Grid'>
            <div className='Commission__content'>
                <div className='Commission__description'>
                    <h2 className='SectionTitle'>
                        {t('PaymentAndConditions.Commission', 'Размер комиссии')}
                    </h2>
                    <h4>
                        {t('PaymentAndConditions.CommissionText', 'Комиссия по защищенным сделкам, которые организовываются и исполняются сообща с партнерами, может отличаться от указанных. Справка о комиссии на подобных сделках показана на веб-сайте партнера или при создании сделки.')}
                    </h4>
                </div>
                <div className='FrameFeature__item'>
                    <div className='FrameFeature-line'/>
                    <span>6,5%</span>
                    <span>{t('PaymentAndConditions.CommissionPercentSubtitle', 'при любой сумме сделки')}</span>
                    <div className='FrameFeature-line'/>
                </div>
            </div>
        </section>
    )
}