import React, {FC} from "react";
import './style.css'
import {FeaturesList} from "../../../uiSections/FeaturesList";
import {useTranslation} from "react-i18next";


export const InfoList:FC = () => {
    const {t} = useTranslation()

    return (
        <section className='InfoList Grid'>
            <h2 className='SectionTitle'>
                {t('HowWorks.InfoList.title', 'Как происходит защита сделки?')}
            </h2>
            <FeaturesList/>
        </section>
    )
}