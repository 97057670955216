import React, {FC} from "react";
import './style.css'
import {AudienceCard} from "./AudienceCard";
import {SliderHook} from "./SliderHook";
import {serviceAudienceImages} from "../../../../assets/images/landings/main";
import {useTranslation} from "react-i18next";
const {
    serviceAudienceImage1,
    serviceAudienceImage2,
    serviceAudienceImage3,
    serviceAudienceImage4
} = serviceAudienceImages

export const ServiceAudience: FC = () => {

    const {
        bind,
        slides,
        slidePrev,
        slideNext,
        tempSlide
    } = SliderHook()
    const {t} = useTranslation()

    /* methods */
    const slideDetectorClasses = (index: number) => {
        return index == tempSlide ? 'slide-detector--active' : ''
    }

    /* data */
    const audienceCardsData = [
        {
            title: t('Main.ServiceAudience.card1.title',
                'Продавцам'),
            text: t('Main.ServiceAudience.card1.text',
                'Если вы продаёте товары и услуги в интернете, то воспользуйтесь сервисом безопасной сделки, чтобы получать 100% предоплату'),
            BgImage: serviceAudienceImage1
        },
        {
            title: t('Main.ServiceAudience.card2.title',
                'Покупателям'),
            text: t('Main.ServiceAudience.card2.text',
                'Если вы хотите купить товар или услугу через интернет, но не до конца доверяете продавцу - создайте безопасную сделку!'),
            BgImage: serviceAudienceImage2
        },
        {
            title: t('Main.ServiceAudience.card3.title',
                'Торговым площадкам'),
            text: t('Main.ServiceAudience.card3.text',
                'Благодаря подключенной системе защиты сделки ваши покупатели будут больше вам доверять!'),
            BgImage: serviceAudienceImage3
        },
        {
            title: t('Main.ServiceAudience.card4.title',
                'E-commerce'),
            text: t('Main.ServiceAudience.card4.text',
                'Подключив систему защищенной сделки, вы привлечёте новых клиентов'),
            BgImage: serviceAudienceImage4
        },
    ]

    return (
        <section className='Main__ServiceAudience Grid'>
            <h2 className='SectionTitle'>{t('Main.ServiceAudience.title', 'Кому полезен наш сервис?')}</h2>
            <div {...bind()} className='Main__ServiceAudience__list'>
                {audienceCardsData.map((item, index) => (
                    <AudienceCard
                        refArray={slides}
                        key={index}
                        index={index}
                        {...item}
                    />
                ))}
                <div className='Main__ServiceAudience__slider-controller' onClick={slidePrev}/>
                <div className='Main__ServiceAudience__slider-controller' onClick={slideNext}/>
            </div>
            <div className='Main__ServiceAudience__slide-detector'>
                {slides.current.map((_, index) =>
                    <span
                        key={index}
                        className={slideDetectorClasses(index)}
                    />
                )}
            </div>
        </section>
    )
}