import React, {useState, useEffect} from "react";

type ReturnedValue = {
    value: string
    mask?: string
    error?: string
    placeholder?: string
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

type PropsType = {
    mask?: string,
    error?: string
    initial?: string,
    placeholder?: string
    callback?: (value: string) => void
}

export const useField = (props: PropsType = {}): ReturnedValue => {

    const {initial = '', callback, mask = '', placeholder = '', error = ''} = props;

    /* state */
    const [value, setValue] = useState<string>(initial);
    const [localError, setLocalError] = useState<string>(error);

    /* side effect */
    useEffect(() => setValue(initial), [initial])
    useEffect(() => setLocalError(error), [error])

    /* methods */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value
        if (callback) callback(value)
        setLocalError('')
        setValue(value)
    }

    return {
        mask,
        value,
        placeholder,
        error: localError,
        onChange: handleChange
    }
}
