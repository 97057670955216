import React, {MutableRefObject, useRef, useState} from "react";
import {ReactEventHandlers} from "react-use-gesture/dist/types";
import {hooks} from "../../../../ui";
const {useDragHook} = hooks

type sliderHookType = {
    bind:  (...args: any[]) => ReactEventHandlers,
    slides: MutableRefObject<(HTMLDivElement | null)[]>,
    slidePrev: ()=>void,
    slideNext: ()=>void,
    tempSlide: number
}
export const SliderHook = ():sliderHookType => {

    const [tempSlide, setTempSlide] = useState(0)
    const [currentOffset, setCurrentOffset] = useState(0)
    const slides = useRef<(HTMLDivElement | null)[]>([])

    let width = document.documentElement.offsetWidth - 32;
    if (width == undefined) width = 0;
    const slideNext = () => {
        const offset = tempSlide!=3 ? currentOffset - (width ? width : 0) : 0;

        slides.current.map(item => {
            item!.style.transform = `translateX(${offset}px)`
        })
        setCurrentOffset(offset)
        setTempSlide((tempSlide+1)%4)
    }

    const slidePrev = () => {
        const offset = tempSlide ? currentOffset + (width ? width : 0) : -width*3;

        slides.current.map(item => {
            item!.style.transform = `translateX(${offset}px)`
        })
        setCurrentOffset(offset)
        setTempSlide(tempSlide == 0 ? 3 : tempSlide-1)
    }

    const {bind} = useDragHook({slideNext, slidePrev})

    return {
        bind,
        slides,
        slideNext,
        slidePrev,
        tempSlide
    }
}