import React, {FC} from 'react'
import errorIcon from './Error.svg'
import {Trans} from "react-i18next";

export const Error: FC = () => {


    return (
        <div className='Status'>
            <img src={errorIcon} alt='#'/>
            <div>
                <Trans i18nKey='Deal.CardConnect.status.error'>
                    Оплата не удалась.<br/> Введите другую карту.
                </Trans>
            </div>
        </div>
    )
}