import React, {FC, useEffect, useRef} from "react";

interface IRedirectForm extends React.FormHTMLAttributes<HTMLFormElement> {
    params: object[]
}

export const RedirectForm: FC<IRedirectForm> = (props) => {

    const {params, ...form} = props;


    const submitRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (submitRef.current) {
            submitRef.current?.click()
        }
    }, [submitRef])

    return (
        <form {...form}>
            {params.map((params) => {

                const [key] = Object.keys(params);
                const [value] = Object.values(params);

                return <input hidden name={key} value={value}/>
            })}
            <input hidden type="submit" ref={submitRef}/>
        </form>
    )
}
