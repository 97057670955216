import React, {FC} from "react";
import './style.css'
import {hooks, ui} from "../../../ui";
import {actions, selectors} from "../../../../store/DealReducer";
import {InformationBlock} from "../../../ui/InformationBlock";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {dealSectionImages} from "../../../../assets/images/landings/main";
import {useTranslation} from "react-i18next";
import {routes} from "../../../../routes";
import {useCommission} from "../../../Deal/useCommission";

const {
    dealBg
} = dealSectionImages
const {AlertContainer, Button, Input, Rub} = ui;

export const DealSection: FC = () => {

    /* hooks */
    const dispatch = useDispatch();
    const history = useHistory();
    const commission = useSelector(selectors.getCommission)
    const {t} = useTranslation()

    /* state */
    const [sumError, setSumError] = React.useState('')
    const [emailError, setEmailError] = React.useState('')

    const sum = hooks.useField({
        placeholder: t('Main.DealSection.sumField', 'Сумма'),
        error: sumError,
        mask: "9".repeat(10)
    })
    const email = hooks.useField({placeholder: t('Main.DealSection.emailField', 'Почта'), error: emailError})


    /* methods */
    const handleSetData = () => {
        if (sum.value && email.value) {
            dispatch(actions.setSum(Number(sum.value)))
            dispatch(actions.setSellerEmail(email.value))
            history.push(routes.operation.creating)
        } else {
            setSumError(sum.value ? "" : t('Main.DealSection.sumError', "Введите сумму"))
            setEmailError(email.value ? "" : t('Main.DealSection.emailError', "Введите E-mail"))
        }
    }


    const calcCommission = useCommission({
        commission: Number(process.env.REACT_APP_COMMISSION),
        minimal: Number(process.env.REACT_APP_MINIMAL_AMOUNT),
        amount: Number(sum.value),
    })

    return (
        <section className='Grid DealSection'>
            <AlertContainer>
                <h3>
                    {t('Main.DealSection.title1', "Проведите безопасную сделку прямо сейчас")}
                </h3>
                <h5>
                    {t('Main.DealSection.title2', "Для расчета комиссии введите сумму. Нажмите \"Начать сделку\" и заполните форму для оформления безопасной сделки")}
                </h5>
                <div className="DealSection__form">
                    <Input
                        label={t('Main.DealSection.sumField', "Сумма")}
                        support={<Rub/>} {...sum}
                    />
                    <Input
                        label={t('Main.DealSection.emailField', "Почта")}
                        {...email}
                    />
                    <InformationBlock
                        label={t('Main.DealSection.commission', 'Комиссия за сделку:')}
                    >
                        {calcCommission.calculations} <Rub size={14}/>
                    </InformationBlock>
                    <InformationBlock
                        label={t('Main.DealSection.merchantReceives', 'Продавец получает:')}
                    >
                        {calcCommission.amountSeller} <Rub size={14}/>
                    </InformationBlock>
                </div>
                <Button onClick={handleSetData}>
                    {t('Main.DealSection.button', 'Начать сделку')}
                </Button>
            </AlertContainer>
            <img src={dealBg} className='DealSection__bg' alt='Волны'/>
        </section>
    )
}
