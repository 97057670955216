import lineAlert from './LineAlert.svg'
import logoFullWhite from './LogoFullWhite.svg'
import logoFullBlack from './LogoFullBlack.svg'
import arbitrationIcon from './statusesIcons/arbitration.svg'
import messageIcon from './statusesIcons/message.svg'
import closedIcon from './statusesIcons/closed.svg'
import deliveredIcon from './statusesIcons/delivered.svg'
import deliveryIcon from './statusesIcons/delivery.svg'
import expectingConfirmIcon from './statusesIcons/expectingConfirm.svg'
import expectingPaymentIcon from './statusesIcons/expectingPayment.svg'
import paidIcon from './statusesIcons/paid.svg'
import returnIcon from './statusesIcons/return.svg'
import walletIcon from './statusesIcons/walletIcon.svg'
import cardIcon from './statusesIcons/cardIcon.svg'


export const commonImages = {
    lineAlert,
    logoFullWhite,
    logoFullBlack,
    statusIcons: {
        arbitrationIcon,
        messageIcon,
        closedIcon,
        deliveredIcon,
        deliveryIcon,
        expectingConfirmIcon,
        expectingPaymentIcon,
        paidIcon,
        returnIcon,
        walletIcon,
        cardIcon
    }
}