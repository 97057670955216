import React, {FC} from 'react'
import {informationImages} from "../../../../../assets/images/landings/main";
import './style.css'
import {useTranslation} from "react-i18next";

const {
    informationListItem,
} = informationImages

export const InformationList: FC = () => {
    const {t} = useTranslation()

    const listData = [
        t('Main.DealInformation.item1','продавец и покупатель договорились о безопасной сделке'),
        t('Main.DealInformation.item2','покупатель переводит деньги на безопасный счет'),
        t('Main.DealInformation.item3','продавец осуществляет передачу товара или услуги покупателю'),
        t('Main.DealInformation.item4','покупатель принимает товар, проверяет его соответствие условиям сделки'),
        t('Main.DealInformation.item5','наш сервис переводит деньги продавцу и завершает безопасную сделку'),
    ]

    return (
        <ul className='DealInformation__list'>
            {listData.map((item, index) => (
                <InformationListItem key={index} index={index}>{item}</InformationListItem>
            ))}
        </ul>
    )
}

const InformationListItem: FC<{ index: number }> = (props) => {
    const {
        children,
        index
    } = props

    return (
        <li className='DealInformation__list__item'>
            <img className='DealInformation__list__item__img' src={informationListItem}/>
            <span>{index+1}</span>
            <div className='DealInformation__list__item__text'>{children}</div>
            <img className='DealInformation__list__item__img' src={informationListItem}/>
        </li>
    )
}