import React, {FC} from "react"
import rub from '../../../assets/images/common/rub.svg'

type PropsType = {
    size?: number
}

export const Rub: FC<PropsType> = (props) => {

    const {size = 16} = props;

    return <img src={rub} height={size} alt="rub"/>
}