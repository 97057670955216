export const routes = {
    root: '/',
    about: '/about',
    howWorks: '/how-works',
    conditions: '/conditions',
    serviceRules: '/service-rules',
    solvingConflicts: '/solving-conflicts',
    operation: {
        root: '/operation',
        info: "/operation/deal-info",
        creating: "/operation/creating-deal",
        arbitration: {
            root: "/operation/arbitration",
            success: "/operation/arbitration/success"
        },
        cardConnect: {
            root: "/operation/card-connect",
            error: "/operation/card-connect/error",
            loading: "/operation/card-connect/loading",
            success: "/operation/card-connect/success",
        }
    }

}
